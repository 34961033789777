import axios from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { SearchIcon } from "../../../../assets";
import { EmptyState, Loader } from "../../../../components";
import { UPDATE_COMPANY } from "../../../../store";

export const FindBusiness = () => {
	const { values, handleChange, setFieldValue } = useFormik({ initialValues: { business_name: "", companies: [] } });
	const [isLoading, setIsLoading] = useState("idle");
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const fecthCompanies = (name) => {
		setIsLoading("loading");
		axios.get(`request/company-search?q=${name}`).then((res) => {
			if (res.status === 200) {
				setFieldValue("companies", res.data);
				setIsLoading("resolved");
			} else setIsLoading("error");
		}).catch(() => {
			setIsLoading("error");
		});
	};

	const search = (e) => {
		e.preventDefault();
		fecthCompanies(values.business_name);
	};

	const selectCompany = (company) => {
		dispatch({
			type: UPDATE_COMPANY,
			payload: {
				business_name: company.title,
				company_number: company.company_number,
				company_status: company.company_status,
				post_code: company.address.postal_code,
				building_number: company.address.premises,
				street_address1: company.address.address_line_1,
				street_address2: company.address.address_line_2,
				city: company.address.locality
			}
		});
		navigate("/business-account/details");
	};

	return (
		<div className="mainBody comp overflow-auto">
			<h2 className="fw-bold text-prim2">Find your business</h2>
			<p className="fw-light text-muted mb-0">
				Enter your company name, so we can help find your
				<br />
				business details.{" "}
			</p>

			<form className="my-5 border rounded small d-flex sticky-top" onSubmit={search}>
				<input
					className="flex-grow-1 border-0 rounded p-3"
					type={"text"}
					value={values.business_name}
					onChange={handleChange}
					name="business_name"
					placeholder="Enter your business name"
				/>
				<button className="btn bg-prim2 p-3 px-4 border-none" type="submit" disabled={isLoading === "loading"}>
					{<SearchIcon width={30} height={20} />}
				</button>
			</form>

			<div className="flex-grow-1 overflow-auto">
				{
					isLoading === "loading"
						? <div className="d-flex flex-column align-items-center justify-content-center py-5 my-5 w-100">
							<Loader />
							<p className="small text-muted mt-2">Please wait while we fetch that for you...</p>
						</div>
						: values?.companies?.length
							? values?.companies?.map((item) => (
								<div className="cursor-pointer" key={Math.random()} onClick={() => selectCompany(item)}>
									<div className="border rounded p-3 mb-3 fade show">
										<div className="d-flex align-items-center justify-content-between mb-2">
											<p className="fw-bold fst-normal text-trucate text-prim2 mb-0">
												{item?.title}
											</p>
											<span className={`px-2 py-1 rounded-pill mb-0 small fw-bold ${item?.company_status === "active" ? "text-greenTag bg-lightGreen" : "text-muted bg-light"}`}>
												{item?.company_status}
											</span>
										</div>
										<p className="mb-0 text-muted small">
											{item?.address?.address_line_1}<br />
											{item?.address?.locality}{item?.address?.region ? `, ${item?.address?.region}.` : "."}
										</p>
									</div>
								</div>
							))
							: isLoading === "resolved" && <EmptyState />
				}
			</div>

			<div className="mb-5 mt-3">
				<p className="fw-bold text-prim2">Can’t find your business?</p>
				<p>
					Please check spelling or contact{" "}
					<Link to="#" className="text-prim2 fw-bold">
						support
					</Link>
				</p>
			</div>

			<p className="fw-bold text-prim">
				<Link to="/business-account">Previous step</Link>
			</p>
		</div>
	);
};
