import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { GreenTick, LogoIcon, WhiteArrow } from "../../assets";
import waves from "../../assets/svg/authWaves.svg";

export function NonUkLayout() {
	const navigate = useNavigate();
	const [sidebarData, setSidebarData] = useState([
		{
			id: 1,
			isActive: true,
			isCurrent: false,
			path: "",
			label: "PERSONAL DETAILS"
		},
		{
			id: 2,
			isActive: false,
			isCurrent: false,
			path: "business-account",
			label: "LOCATION"
		},
		{
			id: 3,
			isActive: false,
			isCurrent: false,
			path: "non_uk",
			otherPath: ["non_uk", "address"],
			label: "BUSINESS PROFILE",
			children: [
				{
					path: "non_uk",
					label: "Business details",
					isActive: false,
					isCurrent: false
				},
				{
					path: "address",
					label: "Business address",
					isActive: false,
					isCurrent: false
				}
			]
		}
	]);

	const [keyGen, setKeyGen] = useState(Math.random());
	const { pathname } = useLocation();
	const pathLength = pathname.split("/");

	useEffect(() => {
		const sidebarDetails = sidebarData;
		let isNew = false;

		// O(n2) //very bad
		/** O(n) */
		for (let index = 0; index < sidebarDetails.length; index++) {
			const element = sidebarDetails[index];

			if (element.path === pathLength[pathLength.length - 1] || element?.otherPath?.includes(element?.path)) {
				element.isCurrent = true;
				let newChild = false;
				const childrenElements = element?.children;

				if (childrenElements) {
					/** O(n) */
					for (let index = 0; index < childrenElements.length; index++) {
						const child = childrenElements[index];

						if (child.path === pathLength[pathLength.length - 1]) {
							child.isCurrent = true;
							newChild = true;
						} else {
							child.isCurrent = false;
							child.isActive = !newChild;
						}
					}
				}

				isNew = true;
				break;
			} else {
				element.isCurrent = false;
				element.isActive = !isNew;
			}
		}

		setSidebarData(sidebarDetails);
		setKeyGen(Math.random());
	}, [pathname]);

	return (
		<div className="d-flex auth">

			{/* logo banner */}
			<div className="col-12 d-flex justify-content-between align-items-center position-absolute p-5" style={{ zIndex: 1 }}>
				<LogoIcon className="mx-3 cursor-pointer" width={180} onClick={() => navigate("business-account")} />
				<p className="mb-0">
					Having trouble?{" "}
					<Link to={"/"} className="fw-normal text-prim2">
						Get help
					</Link>
				</p>
			</div>

			<aside
				className="d-none d-md-flex flex-column col-md-4 bg-prim"
				key={keyGen}
			>
				<div className="flex-grow-1 mainBody">
					{sidebarData.map(({ id, label, isActive, isCurrent, children }) => (
						<>
							<div
								key={label}
								className={"d-flex align-items-center mb-3"}
							>
								{isActive ? ( //eslint-disable-line
									<GreenTick />
								) : (
									<p className="small border rounded-circle optionText mb-0 text-white d-flex align-items-center justify-content-center">
										{id}
									</p>
								)}

								<div
									className={
										"d-flex justify-content-between align-items-center w-100"
									}
								>
									<p
										className={`text-nowrap ms-3 mb-0 ${isActive || isCurrent ? " text-white" : " text-muted"}`}
									>
										{label}
									</p>
									{isCurrent ? children ? "" : <WhiteArrow /> : ""}
								</div>
							</div>

							{
								children && isCurrent &&
								children.map(({ label, isActive, isCurrent }) => (
									<div className={"d-flex align-items-center mb-2"} key={label + "_" + isActive}>
										<p className="small border rounded-circle optionText mb-0 text-white d-flex align-items-center justify-content-center invisible">
											{id}
										</p>

										<div
											className={
												"d-flex justify-content-between align-items-center w-100"
											}
										>
											<p
												className={`text-nowrap ms-3 mb-0 ${isActive || isCurrent ? " text-white" : " text-muted"}`}
											>
												{label}
											</p>
											{isCurrent && <WhiteArrow />}
										</div>
									</div>
								))
							}
						</>
					))}
				</div>

				<img src={waves} />
			</aside>

			<main className="col-md-8 p-5 h-100 d-flex flex-column overflow-auto">
				<div className="col-md-9 col-xl-6 px-5">
					<Outlet />
				</div>
			</main>
		</div>
	);
}
