import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { usePostRequest } from "../../../api/useRequestProcessor";
import { Loader } from "../../../components";
import { CheckoutForm } from "./component";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export const Checkout = () => {
	const { state } = useLocation();
	const navigate = useNavigate();
	const { mutate: getPaymentIntent } = usePostRequest(`/transactions/${state}`, "payment-intent");
	const [clientSecret] = useState("");

	useEffect(() => {
		if (!state) navigate("/");
		else createPaymentIntent();
	}, [state]);

	const createPaymentIntent = () => {
		getPaymentIntent(null, {
			onSuccess: data => {
				// setClientSecret(data?.reference);
				window.location.replace(data?.reference);
			},
			onError: err => {
				console.log({ err });
			}
		});
	};

	const appearance = { theme: "stripe" };

	const options = {
		clientSecret,
		appearance
	};

	return (
		<div className="container">
			{clientSecret
				? (
					<Elements options={options} stripe={stripePromise}>
						<CheckoutForm />
					</Elements>
				)
				: <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
					<Loader size={"bg"} />
					<p className="text-muted mt-3">Connecting to stripe...</p>
				</div>
			}
		</div>
	);
};
