import React from "react";
import "./loader.css";

export const Loader = ({ size }) => {  // eslint-disable-line
	return (
		<div className={`loading ${size || ""}`}>
			<div className="arc"></div>
			<div className="arc"></div>
			<div className="arc"></div>
		</div>
	);
};
