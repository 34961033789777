import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { WhiteArrow } from "../../../../assets";
import { Input } from "../../../../components";

export const BusinessDetails = () => {
	const navigate = useNavigate();
	const { regPayload } = useSelector(state => state.auth);
	const dispatch = useDispatch();

	const { values, touched, errors, setValues } = useFormik(
		{ initialValues: { business_name: "", company_status: "", company_number: "" } }
	);

	const handleSubmit = (e) => {
		e.preventDefault();

		dispatch({ type: "UPDATE_COMPANY", payload: values });
		navigate("/business-account/address");
	};

	useEffect(() => {
		setValues({ ...values, ...regPayload });
	}, [regPayload]);

	return (
		<div className="mainBody comp overflow-auto">
			<h2 className="fw-bold text-prim2">Business details</h2>
			<p className="fw-light text-muted mb-5">
				{values.isUK ? "Please confirm this are your business information" : "Let us know about your business, so we can get you started."}
			</p>

			<form onSubmit={handleSubmit}>
				<Input
					label={"Business name"}
					name="business_name"
					value={values.business_name}
					required
					disabled
					isTouched={touched.business_name}
					error={errors.business_name}
					placeholder="example@mail.com"
				/>

				<div className="d-flex my-4">
					<Input
						viewClass={"w-50 pe-3"}
						label={"Company number"}
						name="company_number"
						value={values.company_number}
						disabled
						isTouched={touched.company_number}
						error={errors.company_number}
						placeholder="0023952093"
					/>
					<Input
						viewClass={"w-50 ps-3"}
						label={"Status"}
						name="company_status"
						value={values.company_status}
						disabled
						isTouched={touched.company_status}
						error={errors.company_status}
						placeholder="0023952093"
					/>
				</div>

				<div className="d-flex align-items-center mt-5">
					<Link className="text-prim2 fw-normal" to={"/business-account/find"}>
						Previous step
					</Link>

					<button type={"submit"} className="btn bg-prim text-white text-center p-3 ms-4 px-4">
						Continue <WhiteArrow className="ms-3" />
					</button>
				</div>
			</form>
		</div>
	);
};
