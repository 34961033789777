import { useFormik } from "formik";
import React from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { usePostRequest } from "../../../api/useRequestProcessor";
import { emailValidation, Input } from "../../../components";
import { useDispatch } from "react-redux";

export const ForgotPassword = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { mutate: validate, isLoading } = usePostRequest("auth/forgot-password");

	const onSubmit = (values) => {
		validate(({ email: values.email }), {
			onSuccess: (data) => {
				toast.success(data?.message);
				dispatch({ type: "TEST_LOGIN", payload: data.confirmation_token });
				navigate("/password-verification", { state: { mail: values.email } });
			},
			onError: ({ error }) => {
				toast.error(error?.message);
			}
		});
	};

	const { values, handleChange, errors, touched, handleSubmit } = useFormik({
		initialValues: { email: "", password: "" },
		validationSchema: emailValidation,
		onSubmit
	});

	return (
		<div>
			<h2 className="fw-bold text-prim2">Password Recovery</h2>
			<p className="fw-light text-muted mb-5">
				Enter the registered email to recover your password{" "}
			</p>

			<form onSubmit={handleSubmit}>
				<Input
					error={errors.email}
					isTouched={touched.email}
					handleChange={handleChange}
					label={"Email"}
					name={"email"}
					value={values.email}
					placeholder="example@gmail.com"
				/>

				<button type={"submit"} disabled={isLoading} className="btn bg-prim text-white w-100 text-center mb-4 mt-5 p-3">
					{isLoading ? "Please wait..." : "Reset Password"}
				</button>

				<div>
					<p className="text-muted">
						Didn’t get any code?{" "}
						<a className="text-prim2 fw-normal" href="">
							Send a new code
						</a>
					</p>
				</div>
			</form>
		</div>
	);
};
