import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DummyChart, DummyChartH, DummyChartL, DummyChartM, HighRisk, LockedTabIcon, LowRisk, MediumRisk, SMap } from "../../../assets";
import { GeoResultCard, Loader, OtherResultCard, PepResultCard, ResultCard, SanctionResultCard } from "../../../components";
import { useGetResquest, usePutRequest } from "../../../api/useRequestProcessor";
import { EmptyResult } from "./emptyResult";
import { useDispatch } from "react-redux";
import { SET_PAGE_TITLE } from "../../../store";
import { toast } from "react-toastify";

let timeout;
export const Result = () => {
	const { id } = useParams();

	const { data: result, isLoading, refetch } = useGetResquest("/results/" + id, "singleResult" + id);
	const { mutate: resultUnlock, isLoading: loadingunlock } = usePutRequest(`/results/${id}/unlock`, `unlockResult/${id}`);
	const [activeTab, setActiveTab] = useState(null);
	const [tabIndex, setTabIndex] = useState(0);
	const navigate = useNavigate(); //eslint-disable-line
	const dispatch = useDispatch();

	useEffect(() => {
		if (result) setActiveTab(result?.data?.result_data?.risk_calculation_result[0]);
		dispatch({ type: SET_PAGE_TITLE, payload: `${result?.data?.methodology_name.toUpperCase().split("-").join(" ") ?? "..."} ASSESSMENT RESULTS` });

		return () => {
			clearTimeout(timeout);
		};
	}, [result]);

	const renderRisks = (risk, type) => {
		if (result.data.unlocked || type?.toLowerCase().includes("geography")) {
			switch (risk.toLowerCase()) {
				case "low":
					return <LowRisk />;
				case "medium":
					return <MediumRisk />;
				case "high":
					return <HighRisk />;
				default:
					break;
			}
		} else return <LockedTabIcon />;
	};

	const selectTab = (item, index) => {
		setActiveTab(item);
		setTabIndex(index);
	};

	// eslint-disable-next-line
	const renderResults = () => {
		if (result?.data?.unlocked || activeTab?.risk_element?.toLowerCase().includes("geography")) {
			return tabIndex === 0
				? activeTab?.roles?.map((item, index) => (
					<ResultCard
						key={"tab" + index}
						name={item.full_name}
						type={item?.risk_rating.toLowerCase()}
						verificationSuccess={item.risk_rating.toLowerCase().includes("low")}
						message={item.decision}
						role={item.role_name}
						amlCheck={result?.data?.meta[item.role_name].ukcredit}
						deceasedCheck={result?.data?.meta[item.role_name].ukdeceased}
					/>
				))
				: tabIndex === 1
					? activeTab?.roles?.map((item, index) => (
						<PepResultCard
							key={"tab" + index}
							name={item.full_name}
							type={item?.risk_rating.toLowerCase()}
							verificationSuccess={item.risk_rating.toLowerCase().includes("low")}
							message={item.decision}
							role={item.role_name}
							category={result?.data?.meta[item.role_name].pepdata.tier}
							positions={result?.data?.meta[item.role_name].pepdata.positions}
							country={result?.data?.meta[item.role_name].pepdata.country}
						/>
					))
					: tabIndex === 2
						? activeTab?.roles?.map((item, index) => (
							<SanctionResultCard
								key={"tab" + index}
								name={item.full_name}
								type={item?.risk_rating.toLowerCase()}
								verificationSuccess={item.risk_rating.toLowerCase().includes("low")}
								message={item.decision}
								role={item.role_name}
								sanctions={result?.data?.meta[item.role_name].sanctionbodies}
							/>
						))
						: tabIndex === 3
							? activeTab?.roles?.map((item, index) => (
								<GeoResultCard
									key={"tab" + index}
									name={item.full_name}
									type={item?.risk_rating.toLowerCase()}
									verificationSuccess={item.risk_rating.toLowerCase().includes("low")}
									message={item.decision}
									role={item.role_name}
									nationalities={item?.extras?.nationality}
									residency={item?.extras?.residency}
									extras={item?.extras.others}
								/>
							))
							: activeTab?.roles?.map((item, index) => (
								<OtherResultCard
									key={"tab" + index}
									name={item.full_name}
									type={item?.risk_rating.toLowerCase()}
									verificationSuccess={item.risk_rating.toLowerCase().includes("low")}
									message={item.decision}
									role={item.role_name}
								/>
							));
		} else return <EmptyResult unlockResult={unlockResult} disabled={loadingunlock} />;
	};

	const unlockResult = () => {
		resultUnlock(id, {
			onSuccess: (data) => {
				if (data.status === "success") {
					refetch();
					toast.success(data.message);
				} else {
					toast.error(data?.message);
				}
			},
			onError: (err) => {
				if (err?.status === "Unauthorized" || !err?.meta?.sufficient_balance) {
					navigate(`/assessment/plan/${id}`);
				} else if (err.error.name === "resultAlreadyUnlocked") toast.warn(err.error?.message);
			}
		});
	};

	return (
		((!isLoading) && result?.data)
			? <div className="row h-100 overflow-auto p-md-5 m-n5">
				<div className="col-md-8 h-50">
					<div className="customShadow rounded p-5">
						<div className="d-flex border-bottom mb-5">
							{
								result?.data?.result_data?.risk_calculation_result?.filter((item) => Boolean(item.risk_element)).map((item, index) => (
									<div
										key={"risk_" + index}
										onClick={() => selectTab(item, index)}
										className={"d-flex flex-column align-items-center w-25 pb-4 cursor-pointer" + (activeTab?.risk_element === item.risk_element ? " border-bottom border-prim2 text-prim2" : " text-muted")}
									>
										{renderRisks(item.risk_rating, item.risk_element)}
										<p className="mb-0 mt-3 text-center">
											{item.risk_element.replace(/CHECK/g, " ")}
										</p>
									</div>)
								)
							}
						</div>

						<div className="">
							<p className="text-muted fw-bold text-capitalize"> {activeTab?.risk_element} results </p>

							<div className="mt-3">
								{renderResults()}
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-4">
					<div className="customShadow rounded p-5">
						<p className="text-prim2 text-center fw-bold">Assessment Details</p>

						<div className="chart my-5 d-flex flex-column align-items-center justify-content-center">
							{
								result.data.unlocked
									? result?.data?.result_data?.final_rating.toLowerCase() === "low"
										? <DummyChart className="mx-auto w-75 d-flex" />
										: result?.data?.result_data?.final_rating.toLowerCase() === "high"
											? <DummyChartH className="mx-auto w-75 d-flex" />
											: <DummyChartM className="mx-auto w-75 d-flex" />

									: <DummyChartL className="mx-auto w-75 d-flex" />
							}

							<p className="text-muted text-center small mt-1">{result.data.unlocked ? "Risk level" : "UNLOCK TO VIEW"}</p>
						</div>

						<div className="d-flex mb-4 pt-5">
							<div style={{ width: 40 }}>
								<SMap />
							</div>

							<div className="d-flex flex-column ms-3">
								<p className="text-muted mb-0 fs-6">Assessment name</p>
								<p className="mb-0 fs-6 text-prim2">{result?.data.name}</p>
							</div>
						</div>

						<div className="d-flex mb-4">
							<div style={{ width: 40 }}>
								<SMap />
							</div>

							<div className="d-flex flex-column ms-3">
								<p className="text-muted mb-0 fs-6">Assessment type</p>
								<p className="mb-0 fs-6 text-prim2 text-capitalize">{result?.data?.methodology_name?.split("-").join(" ")}</p>
							</div>
						</div>

						<div className="d-flex mb-4">
							<div style={{ width: 40 }}>
								<SMap />
							</div>

							<div className="d-flex flex-column ms-3">
								<p className="text-muted mb-0 fs-6">Date created</p>
								<p className="mb-0 fs-6 text-prim2">{new Date(result.data.created_at).toLocaleDateString()}</p>
							</div>
						</div>

						<div className="d-flex mb-4">
							<div style={{ width: 40 }}>
								<SMap />
							</div>

							<div className="d-flex flex-column ms-3">
								<p className="text-muted mb-0 fs-6">Available till</p>
								<p className="mb-0 fs-6 text-prim2">{new Date(result.data.available_until).toLocaleDateString()}</p>
							</div>
						</div>

						{result.data.unlocked
							? <button className="btn py-3 bg-prim2 w-100 text-white mt-5">Download report</button>
							: <button className="btn py-3 bg-prim2 w-100 text-white mt-5" onClick={unlockResult} disabled={loadingunlock}>{loadingunlock ? "Please wait..." : "Unlock report"}</button>
						}

					</div>
				</div>
			</div>
			: <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
				<Loader size={"lg"} />
				<p className="text-muted mt-3 mb-0">Fetching result data...</p>
			</div>
	);
};
