import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { WhiteArrow } from "../../../../assets";
import { Input } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { LOGIN, UPDATE_COMPANY } from "../../../../store";
import { usePostRequest } from "../../../../api/useRequestProcessor";
import { toast } from "react-toastify";

let timeout;
export const BusinessAddress = () => {
	const dispatch = useDispatch();
	const { regPayload } = useSelector(state => state.auth);
	const { mutate: updateCompany, isLoading } = usePostRequest("auth/submit-company");

	const onSubmit = (values) => {
		dispatch({ type: UPDATE_COMPANY, payload: { ...values } });

		delete values.countryCode;

		updateCompany(values, {
			onSuccess: (response) => {
				toast.success("Company profile successfully submitted!");

				timeout = setTimeout(() => {
					dispatch({ type: LOGIN, payload: response?.access_token });
					clearTimeout(timeout);
				}, 1000);
			},
			onError: ({ error }) => {
				toast.error(error?.message);
			}
		});
	};

	const { values, handleChange, errors, touched, setValues, handleSubmit } = useFormik({
		initialValues: { building_number: "", street_address1: "", street_address2: "", post_code: "", state: "", city: "" },
		onSubmit
	});

	useEffect(() => {
		setValues({ ...values, ...regPayload });
	}, [regPayload]);

	return (
		<div className="mainBody comp overflow-auto">
			<h2 className="fw-bold text-prim2">{"Business address"}</h2>
			<p className="fw-light text-muted mb-5">
				You are almost there, just a few more information.
			</p>

			<form onSubmit={handleSubmit} className="mb-5">

				<Input label={"Building Number/Name"}
					name="building_number"
					value={values.building_number}
					onChange={handleChange}
					isTouched={touched.building_number}
					error={errors.building_number}
					placeholder=""
				/>

				<Input viewClass={"mt-4"}
					label={"Address Line 1"}
					name="street_address1"
					value={values.street_address1}
					required
					onChange={handleChange}
					isTouched={touched.street_address1}
					error={errors.street_address1}
					placeholder=""
				/>

				<Input viewClass={"mt-4"}
					label={"Address Line2"}
					name="street_address2"
					value={values.street_address2}
					// required
					onChange={handleChange}
					isTouched={touched.street_address2}
					error={errors.street_address2}
					placeholder=""
				/>

				<Input viewClass={"mt-4"}
					label={"City"}
					name="city"
					value={values.city}
					required
					onChange={handleChange}
					isTouched={touched.city}
					error={errors.city}
					placeholder=""
				/>

				<div className="d-flex my-4">
					<Input
						viewClass={"w-50 pe-3"}
						label={"State / Province (optional)"}
						name="state"
						value={values.state}
						onChange={handleChange}
						isTouched={touched.state}
						error={errors.state}
						placeholder="State"
					/>

					<Input
						viewClass={"w-50 ps-3"}
						label={"ZIP / Post code"}
						name="post_code"
						value={values.post_code}
						required
						onChange={handleChange}
						isTouched={touched.post_code}
						error={errors.post_code}
						placeholder="439 045"
					/>
				</div>

				<Input
					label={"Country"}
					name="country"
					value={values.country}
					disabled
					required
					onChange={handleChange}
					isTouched={touched.country}
					error={errors.country}
					placeholder="439045"
				/>

				<div className="d-flex align-items-center mt-5">
					<Link className="text-prim2 fw-normal" to="/business-account/details">
						Previous step
					</Link>

					<button type="submit" className="btn bg-prim text-white text-center p-3 ms-4 px-4" disabled={isLoading}>
						{isLoading ? "please wait..." : <>Submit <WhiteArrow className="ms-3" /></>}
					</button>
				</div>

			</form>
		</div >
	);
};
