import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { usePutRequest } from "../../../api/useRequestProcessor";
import { Input } from "../../../components";
import { SET_PAGE_TITLE } from "../../../store";

/* eslint-disable camelcase */
export const Security = () => {
	const dispatch = useDispatch();
	const [old_password, setPassword] = useState("");
	const [new_password, setNewPassword] = useState("");
	const { mutate: updatePassword, isLoading } = usePutRequest("/auth/password", "update-password");

	useEffect(() => {
		dispatch({ type: SET_PAGE_TITLE, payload: "PASSWORD & SECURITY" });
	}, []);

	const handleSubmit = (e) => {
		e.preventDefault();
		const payload = { old_password, new_password };

		updatePassword(payload, {
			onSuccess: data => {
				toast.success(data?.message);
			},
			onError: () => {
				toast.error("current password passed is not correct");
			}
		});
	};

	return (
		<form onSubmit={handleSubmit}>
			<p className="fs-4 fw-bold text-prim2 mb-2">Password & Security</p>
			<p className="mb-0 text-muted small">It’s a good idea to use password that is not used elsewhere</p>

			<div className="mt-5">
				<Input
					placeholder={"enter password"}
					label={"Current password"}
					viewClass={"mt-4 border-none noFocusWithin"}
					inputstyle={"border-none noFocusWithin"}
					value={old_password}
					type={"password"}
					required
					onChange={(e) => setPassword(e.target.value)}
				/>
				<Input
					placeholder={"enter password"}
					label={"New password"}
					viewClass={"mt-4 border-none noFocusWithin"}
					inputstyle={"border-none noFocusWithin"}
					type={"password"}
					value={new_password}
					required
					onChange={(e) => setNewPassword(e.target.value)}
				/>
			</div>

			<div className="d-flex align-items-center my-5 justify-content-end">
				<button className="btn bg-prim2 text-white btn-sm ms-3 p-3 px-4 rounded fw-bold" type="submit" disabled={isLoading}>
					{isLoading ? "Loading..." : "Change Password"}
				</button>
			</div>
		</form>
	);
};
