import React, { useState } from "react";
import { DashFilter, Minus, Plus } from "../assets";
import { any } from "prop-types";

export const FilterContent = ({ setFilterParam, filterParam, reset }) => {
	const [showFilter, setShowFilter] = useState(false);
	const [toggleStates, setToggleStates] = useState({
		status: true,
		level: true
	});

	return (
		<>
			<DashFilter className="cursor-pointer" onClick={() => setShowFilter(prev => !prev)} />

			{
				showFilter &&
				<div className="dropdown-menu px-3 pt-3 d-block" style={{ width: 320, right: 0 }}>
					<div className="d-flex align-items-center justify-content-between">
						<p className="text-prim2 mb-0 h5">Filters</p>
						<p className="text-prim mb-0 small cursor-pointer" onClick={reset}>Reset</p>
					</div>

					<div className="w-100 my-4">
						<input placeholder="Search" className="form-control" value={filterParam.q} onChange={(e) => setFilterParam(prev => ({ ...prev, q: e.target.value }))} />
					</div>
					<div className={"p-2 border-bottom"}>
						<div onClick={() => setToggleStates(prev => ({ ...prev, level: !prev.level }))} className="d-flex align-items-center justify-content-between cursor-pointer">
							<p className="text-prim2 small mb-0">Risk Level</p>
							{!toggleStates.level ? <Plus width={15} /> : <Minus width={15} />}
						</div>

						{toggleStates.level &&
							<ul className="ps-0">
								<li className="text-muted small mb-0 mt-3 d-flex align-items-center">
									<input type={"radio"} checked={filterParam.level === "low"} onChange={() => setFilterParam(prev => ({ ...prev, level: "low" }))} />
									<p className="mb-0 ms-2">Low Risk</p>
								</li>
								<li className="text-muted small mb-0 mt-3 d-flex align-items-center">
									<input type={"radio"} checked={filterParam.level === "medium"} onChange={() => setFilterParam(prev => ({ ...prev, level: "medium" }))} />
									<p className="mb-0 ms-2">Medium Risk</p>
								</li>
								<li className="text-muted small mb-0 mt-3 d-flex align-items-center">
									<input type={"radio"} checked={filterParam.level === "high"} onChange={() => setFilterParam(prev => ({ ...prev, level: "high" }))} />
									<p className="mb-0 ms-2">High Risk</p>
								</li>
							</ul>
						}
					</div>
					<div className={"p-2"}>
						<div onClick={() => setToggleStates(prev => ({ ...prev, status: !prev.status }))} className="d-flex align-items-center justify-content-between cursor-pointer">
							<p className="text-prim2 small mb-0">Status</p>
							{!toggleStates.status ? <Plus width={15} /> : <Minus width={15} />}
						</div>

						{toggleStates.status &&
							<ul className="ps-0">
								<li className="text-muted small mb-0 mt-3 d-flex align-items-center">
									<input type={"radio"} checked={filterParam.status === "pending"} onChange={() => setFilterParam(prev => ({ ...prev, status: "pending" }))} />
									<p className="mb-0 ms-2">Pending</p>
								</li>
								<li className="text-muted small mb-0 mt-3 d-flex align-items-center">
									<input type={"radio"} checked={filterParam.status === "success"} onChange={() => setFilterParam(prev => ({ ...prev, status: "success" }))} />
									<p className="mb-0 ms-2">Success</p>
								</li>
								<li className="text-muted small mb-0 mt-3 d-flex align-items-center">
									<input type={"radio"} checked={filterParam.status === "failed"} onChange={() => setFilterParam(prev => ({ ...prev, status: "failed" }))} />
									<p className="mb-0 ms-2">Failed</p>
								</li>
							</ul>
						}
					</div>
				</div>
			}
		</>
	);
};

FilterContent.propTypes = {
	setFilterParam: any,
	filterParam: any,
	reset: any
};
