import {
	CHANGE_ASSESSMENT_APPLICANT_1, CHANGE_ASSESSMENT_APPLICANT_2,
	CHANGE_FIRT_TIME_USER, SET_PAGE_TITLE, CHANGE_ASSESSMENT_DETAILS, RESET_ASSESSMENT
} from "..";

const initialState = {
	pageTitle: "",
	firstTimeUser: true,
	assessments: {
		name: "",
		count: "1",
		applicant: [
			{
				role: "main",
				first_name: "",
				last_name: "",
				other_name: "",
				residency: "United Kingdom",
				date_of_birth: "",
				nationality: [],
				electronic_check: false,
				address: {
					building_number: "",
					street_address: "",
					city: "",
					state: "",
					post_code: "",
					country: "United Kingdom"
				}
			},
			{
				role: "co-applicant",
				first_name: "",
				last_name: "",
				other_name: "",
				residency: "United Kingdom",
				date_of_birth: "",
				nationality: [],
				electronic_check: false,
				address: {
					building_number: "",
					street_address: "",
					city: "",
					state: "",
					post_code: "",
					country: "United Kingdom"
				}
			}
		]
	}
};

const DashReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_PAGE_TITLE:
			return { ...state, pageTitle: action.payload };
		case CHANGE_FIRT_TIME_USER:
			return { ...state, firstTimeUser: false };
		case CHANGE_ASSESSMENT_DETAILS:
			return { ...state, assessments: { ...state.assessments, name: action.payload.name, count: action.payload.count } };
		case CHANGE_ASSESSMENT_APPLICANT_1: {
			const mainApplicant = { ...state.assessments.applicant[0], ...action.payload };
			return { ...state, assessments: { ...state.assessments, applicant: [mainApplicant, state.assessments.applicant[1]] } };
		}
		case CHANGE_ASSESSMENT_APPLICANT_2: {
			const coApplicant = { ...state.assessments.applicant[1], ...action.payload };
			return { ...state, assessments: { ...state.assessments, applicant: [state.assessments.applicant[0], coApplicant] } };
		}
		case RESET_ASSESSMENT:
			return { ...initialState, firstTimeUser: false };
		default:
			return state;
	}
};

export default DashReducer;
