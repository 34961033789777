import React, { Component } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Carousel } from "..";
import { LogoIcon } from "../../assets";
export function AuthLayout() {
	const navigate = useNavigate();

	return (
		<div className="d-flex auth">

			{/* logo banner */}
			<div className="col-12 d-flex justify-content-between align-items-center position-absolute p-5" style={{ zIndex: 1 }}>
				<div onClick={() => navigate("/")}>
					<LogoIcon className="mx-3 cursor-pointer" width={180} />
				</div>

				<p className="mb-0">
					Having trouble?{" "}
					<a href="https://google.com" className="fw-normal text-prim2">
						Get help
					</a>
				</p>
			</div>

			<aside className="d-none d-md-flex col-md-4 bg-prim">
				<Carousel />
			</aside>

			<main className="col-md-8 p-5 h-100 d-flex flex-column overflow-auto">
				<div className="col-md-9 col-lg-6 px-5 my-auto">
					<Outlet />
				</div>
			</main>
		</div>
	);
}

AuthLayout.propTypes = { children: Component };
