import React from "react";
import DatePicker from "react-date-picker";
import { Input } from "../forms";

export const DownloadModal = () => {
	return (
		<div className="modal" id="downloadModal" tabIndex="-1" aria-labelledby="downloadModalLabel" aria-hidden="true">
			<div className="modal-dialog modal-lg">
				<div className="modal-content">
					<div className="modal-body m-4">
						<p className="text-center text-prim2 fw-bold fs-4">Download</p>
						<p className="text-center mt-2 text-muted">Customize your report and download all in <br />
							seperate or one file.</p>

						<Input
							viewClass={"mt-4"}
							value={""}
							name={"name"}
							onChange={() => { }}
							label={"Assesment type*"}
						/>

						<Input
							viewClass={"mt-4"}
							value={""}
							name={"name"}
							onChange={() => { }}
							label={"File type*"}
						/>

						<div className="d-flex align-items-center mt-4">
							<div className="me-2 w-50">
								<label className={"fw-bold text-prim2"}>{"From*"}</label>

								<DatePicker
									value={new Date()}
									// onChange={(val) => console.log("date_of_birth", val)}
									className={"mt-2 p-2 border rounded w-100 calendaar"}
								/>
							</div>

							<div className="ms-2 w-50">
								<label className={"fw-bold text-prim2"}>{"To*"}</label>
								<DatePicker
									value={new Date()}
									// onChange={(val) => console.log("date_of_birth", val)}
									className={"mt-2 p-2 border rounded w-100 calendaar"}
								/>
							</div>
						</div>

						<div className="form-check mt-4">
							<input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={() => { }} name={"name"} />
							<label className="form-check-label" htmlFor="flexCheckDefault">
								Merge all ranges in one file
							</label>
						</div>

						<button className="btn py-3 bg-prim2 w-100 text-white mt-5">Download report</button>

					</div>
				</div>
			</div>
		</div>
	);
};
