import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ActiveUser, Case, WhiteArrow } from "../../../assets";
import { SET_PAGE_TITLE, RESET_ASSESSMENT } from "../../../store/constants";

export const SetAccessment = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [type, setType] = useState("");

	useEffect(() => {
		dispatch({ type: RESET_ASSESSMENT });
		dispatch({ type: SET_PAGE_TITLE, payload: "CREATE ASSESSMENT" });
	}, []);

	return (
		<div className="mb-5 col-md-10">
			<p className="fw-bold text-prim2 fs-3 mb-5">Client type</p>
			<div className="row">
				<div className="col-4">
					<div className={`p-5 border rounded round bg-light shadow-sm d-block highLightHover cursor-pointer ${type === "user" ? "border-prim2" : ""}`} onClick={() => setType("user")}>
						<ActiveUser className="mb-4" />
						<p className="text-prim2 fw-bold fs-5 mb-2">Individual</p>
						<p className="mb-0 text-muted"> Perform assessment on client you would like to rent and sell your real estate to. </p>
					</div>
				</div>

				<div className="col-4">
					<div className={`p-5 border rounded round bg-light shadow-sm d-block highLightHover cursor-pointer ${type === "business" ? "border-prim2" : ""}`} onClick={() => setType("business")}>
						<Case className="mb-4" />
						<p className="text-prim2 fw-bold fs-5 mb-2">Business</p>
						<p className="mb-0 text-muted"> Perform assessment on client you would like to rent and sell your real estate to. </p>
					</div>

					<div className="d-flex align-items-center justify-content-end mt-5">
						<Link className="text-prim2 fw-normal" to="/assessment">
							Previous step
						</Link>

						<button type="submit" className="btn bg-prim text-white text-center p-3 ms-4 px-4" disabled={!type} onClick={() => navigate("/assessment/assessment-details")}>
							{<>Continue <WhiteArrow className="ms-3" /></>}
						</button>
					</div>
				</div>
			</div>

		</div>
	);
};
