import { useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { usePostRequest } from "../../../api/useRequestProcessor";
import { Input, loginSchema } from "../../../components";

export const Login = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const onSubmit = (values) => {
		loginUser(values, {
			onSuccess: (data) => {
				toast.success(data.message);
				dispatch({ type: "TEST_LOGIN", payload: data.confirmation_token });
				navigate("/verification", { state: { mail: values.email, fromRoute: "login" } });
			},
			onError: ({ error }) => {
				toast.error(error.message);
			}
		});
	};

	const { values, handleChange, handleSubmit, touched, errors } = useFormik({
		initialValues: { email: "", password: "" },
		onSubmit,
		validationSchema: loginSchema
	});

	const { mutate: loginUser, isLoading } = usePostRequest("auth/login", "login");

	return (
		<div>
			<h2 className="fw-bold text-prim2">Welcome</h2>
			<p className="fw-light text-muted mb-5">
				Login to your account {" "}
			</p>

			<form onSubmit={handleSubmit}>

				<Input
					label={"Email"}
					name="email"
					value={values.email}
					onChange={handleChange}
					isTouched={touched.email}
					error={errors.email}
					placeholder="example@gmail.com"
				/>

				<Input
					label={"Password"}
					viewClass={"mt-4"}
					name="password"
					type={"password"}
					value={values.password}
					onChange={handleChange}
					isTouched={touched.password}
					error={errors.password}
					placeholder="********"
				/>

				<div className="d-flex justify-content-between align-items-center mt-3">
					<div>
						<input type={"checkbox"} aria-label="forgotpassword" />
						<label
							htmlFor="forgotpassword"
							className="ms-2 small text-muted"
						>
							Keep me logged in
						</label>
					</div>

					<Link to={"/forgot-password"}>
						<p className="mb-0 text-prim2 small">Forgot Password</p>
					</Link>
				</div>

				<button type="submit" disabled={isLoading} className="btn bg-prim text-white w-100 text-center mb-4 mt-5 p-3">
					{isLoading ? "Please wait..." : "Login"}
				</button>

				<div>
					<p className="text-muted">
						New to Regcomply?{" "}
						<Link className="text-prim2 fw-normal" to={"/register"}>
							Create an account
						</Link>
					</p>
				</div>
			</form>
		</div>
	);
};
