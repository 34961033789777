import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useGetResquest } from "../../../api/useRequestProcessor";
import { Input } from "../../../components";
import { SET_PAGE_TITLE } from "../../../store";

/* eslint-disable camelcase */

export const Business = () => {
	const dispatch = useDispatch();
	const { data } = useGetResquest("/users/company-profile", "company-profile");

	useEffect(() => {
		dispatch({ type: SET_PAGE_TITLE, payload: "BUSINESS INFORMATION" });
	}, []);

	const { handleSubmit, setValues, values, handleChange } = useFormik({
		initialValues: {
			building_name: "",
			business_name: "",
			city: "",
			company_number: "",
			country: "",
			post_code: "",
			sic_description: "",
			sic_description_extra: "",
			state: "",
			street_address: "",
			street_address2: ""
		},
		onSubmit: () => { }
	});

	useEffect(() => {
		// console.log({ data });
		if (data) {
			const { created_at, id, updated_at, user_id, ...rest } = data?.data;
			setValues({ ...values, ...rest });
		};
	}, [data]);

	return (
		<form onSubmit={handleSubmit}>
			<p className="fs-4 fw-bold text-prim2 mb-2">Business Information</p>
			<p className="mb-0 text-muted small">This information contains your business details.</p>

			<Input
				placeholder={"enter business name"}
				label={"Business name"}
				viewClass={"mt-4"}
				value={values.business_name}
				name={".business_name"}
				onChange={handleChange}
				disabled={true}
			/>

			<div className="d-flex justify-content-between mt-4">
				<Input
					placeholder={"enter company name"}
					viewClass={"w-50 me-3"}
					label={"Company name"}
					value={values.company_number}
					name={"company_number"}
					onChange={handleChange}
					disabled={true}
				/>
				<Input
					placeholder={"enter sic description"}
					viewClass={"w-50 ms-3"}
					label={"SIC description"}
					value={values.sic_description}
					name={"sic_description"}
					onChange={handleChange}
					disabled={true}
				/>
			</div>

			<Input
				placeholder={"building number / name"}
				label={"Address"}
				viewClass={"mt-4"}
				value={values.building_name}
				name={"building_name"}
				onChange={handleChange}
				disabled={true}
			/>
			<Input
				placeholder={"72a, London house"}
				viewClass={"mt-2"}
				value={values.street_address}
				name={"street_address"}
				onChange={handleChange}
				disabled={true}
			/>

			{values.street_address2 && <Input
				placeholder={"72a, London house"}
				viewClass={"mt-2"}
				value={values.street_address2}
				name={"street_address2"}
				onChange={handleChange}
				disabled={true}
			/>}

			{/* <Input
				placeholder={"081722462"}
				label={"Phone Number"}
				viewClass={"mt-4"}
				// value={values.}
				// name={""}
				onChange={handleChange}
			/> */}

			<div className="d-flex justify-content-between mt-4">
				{values.city && <Input
					placeholder={"enter city"}
					viewClass={`${values.state ? "w-50" : "w-100"} me-3`}
					label={"City"}
					value={values.city}
					disabled={true}
					name={"city"}
					onChange={handleChange}
				/>}
				{values.state && <Input
					placeholder={"enter state / province"}
					viewClass={`${values.city ? "w-50" : "w-100"} ms-3`}
					label={"State / Province"}
					value={values.state}
					name={"state"}
					disabled={true}
					onChange={handleChange}
				/>}
			</div>
			<div className="d-flex justify-content-between mt-4">
				<Input
					disabled={true}
					placeholder={"enter code"}
					viewClass={"w-50 me-3"}
					label={"Zip/Postal code"}
					value={values.post_code}
					name={"post_code"}
					onChange={handleChange}
				/>
				<Input
					disabled={true}
					placeholder={"select country"}
					viewClass={"w-50 ms-3"}
					label={"Country"}
					value={values.country}
					name={"country"}
					onChange={handleChange}
				/>
			</div>

			{/* <div className="d-flex align-items-center my-5 justify-content-end">
				<button className="btn btn-sm ms-3">Cancel</button>
				<button className="btn bg-prim2 text-white btn-sm ms-3 p-3 px-4 rounded" type="submit">Save changes</button>
			</div> */}
		</form>
	);
};
