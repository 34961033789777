import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import countries from "../../store/countries";

export const CountryDropdown = ({ label, value, setValue, disabled }) => {
	const [searchString, setSearchString] = useState("");
	const [sorted, setSorted] = useState([]);

	useEffect(() => {
		const filtered = countries.filter(country => {
			return country.country.toLowerCase().includes(searchString.toLowerCase());
		});
		setSorted(filtered);
	}, [searchString]);

	return (
		<div className="d-flex flex-column justify-content-start mt-4" key={label}>
			<p className="fw-bold text-prim2 mb-2">{label}</p>

			<div className="dropdown mt-0">
				<li className="dropdown-item d-flex align-items-center cursor-pointer border p-3" id={"countriesSelect"} data-bs-toggle="dropdown" aria-expanded="false">
					<p className="text-dark mb-0">{value || "select country"}</p>
				</li>

				<ul className="dropdown-menu menuHeight w-100" aria-labelledby="countriesSelect">
					<li className={"dropdown-item d-flex align-items-center"}>
						<input disabled={disabled}
							value={searchString} onChange={(e) => setSearchString(e.target.value)} autoFocus
							placeholder={"search countries..."} className={"w-100 p-2 border"}
						/>
					</li>
					{
						countries?.length
							? sorted?.length
								? sorted?.map((country, index) => (
									<li key={index} className="dropdown-item d-flex align-items-center" onClick={() => setValue(country.country)}>
										<p className={"mb-0 text-truncate"}>{country.country}</p>
									</li>
								))
								: <p className="small p-3 mb-0">{"Search not found!"}</p>
							: <p className="small p-3 mb-0">{"Search not found!"}</p>
					}
				</ul>
			</div>
		</div>
	);
};

CountryDropdown.propTypes = {
	label: PropTypes.string,
	value: PropTypes.string,
	setValue: PropTypes.func,
	disabled: PropTypes.bool
};
