import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useGetResquest, usePutRequest } from "../../../api/useRequestProcessor";
import { Input } from "../../../components";
import { SET_PAGE_TITLE } from "../../../store";

/* eslint-disable camelcase */

export const Profile = () => {
	const dispatch = useDispatch();
	const { data } = useGetResquest("/users", "profile");
	const { mutate: updateProfile, isLoading } = usePutRequest("/users", "profile");
	const [stopretries, setStopRetries] = useState(false);

	useEffect(() => {
		dispatch({ type: SET_PAGE_TITLE, payload: "PROFILE" });
	}, []);

	const { handleSubmit, setValues, values, handleChange } = useFormik({
		initialValues: { first_name: "", last_name: "", email: "", avatar: "" },
		onSubmit: () => {
			updateProfile(values, {
				onSuccess: (res) => {
					toast.success(res.message);
				}
			});
		}
	});

	useEffect(() => {
		if (data && !stopretries) {
			const { first_name, last_name, email } = data?.data;
			setValues({ ...values, first_name, last_name, email });
			setStopRetries(true);
		}
	}, [data]);

	// const handleFileChange = (e) => {
	// setValues({ ...values, avatar: e.target.files[0] });
	// };

	return (
		<form onSubmit={handleSubmit}>
			<p className="fs-4 fw-bold text-prim2 mb-2">Personal Information</p>
			<p className="mb-0 text-muted small">This information contains your personal details</p>

			{/* <div className="mt-4">
				<p className="text-prim2 mb-2">Photo</p>

				<div>
					<img className="rounded-circle border-0 rounded" src={values.avatar || "http://placeimg.com/640/480"} width={56} height={56} />
					<input className="btn bg-prim2 text-white btn-sm ms-3 d-none" type={"file"} accept={"image/png, image/jpeg"} id="avatar" onChange={handleFileChange} name="avatar" />
					<label className="btn bg-prim2 text-white btn-sm ms-3" type="button" htmlFor="avatar">Change</label>
					<label className="btn btn-sm ms-3" type="button">Remove</label>
				</div>
			</div> */}

			<div className="d-flex justify-content-between mt-4">
				<Input
					placeholder={"first name"}
					viewClass={"w-50 me-3"}
					label={"First name"}
					value={values.first_name}
					name={"first_name"}
					onChange={handleChange}
				/>
				<Input
					placeholder={"last name"}
					viewClass={"w-50 ms-3"}
					label={"Last name"}
					value={values.last_name}
					name={"last_name"}
					onChange={handleChange}
				/>
			</div>
			<Input
				placeholder={"mail@example.com"}
				label={"Email address"}
				viewClass={"mt-4"}
				value={values.email}
				name={"email"}
				onChange={handleChange}
			/>

			{/* <Input
				placeholder={"081722462"}
				label={"Phone Number"}
				viewClass={"mt-4"}
				value={values.phone}
				name={"phone"}
				onChange={handleChange}
			/> */}

			<div className="d-flex align-items-center mt-5 justify-content-end">
				<button type="button" className="btn btn-sm ms-3">Cancel</button>
				<button className="btn bg-prim2 text-white btn-sm ms-3 p-3 px-4 rounded" type="submit" disabled={isLoading}>{isLoading ? "Please wait..." : "Save changes"}</button>
			</div>
		</form>
	);
};
