import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Phone, Map, Envelope, ErrorTag } from "../../../assets";
import { sendMessageSchema } from "../../../components";

const categories = ["Real Estate", "Finance"];
const problems = ["I have a question about real estate assess... ", "I have a question about finance...", "I have a question about..."];

export const SupportHome = () => {
	const dispatch = useDispatch();
	const { values, setFieldValue, handleChange, handleSubmit, errors } = useFormik({
		initialValues: {
			category: categories[0],
			title: problems[0],
			message: ""
		},
		onSubmit: values => {
			// console.log(values);
		},
		validationSchema: sendMessageSchema
	});

	useEffect(() => {
		dispatch({ type: "SET_PAGE_TITLE", payload: "Support" });
	}, []);

	return (
		<div className="row">
			<div className="col-md-4">
				<p className="mb-0 text-prim2 fw-bold">Need help with something?</p>
				<small className="text-muted">Fill the form and our team will get back to you
					within 24 hours.
				</small>

				<div className="mt-5">
					<div className="d-flex align-items-center mb-4"><Phone /> <p className="text-prim2 mb-0 ms-3">+44 754780269</p></div>
					<div className="d-flex align-items-center mb-4"><Map /> <p className="text-prim2 mb-0 ms-3">75 avenue, london, UK</p></div>
					<div className="d-flex align-items-center mb-4"><Envelope /> <a href="mailto:support@regcomply.com" className="text-prim2 mb-0 ms-3">Support@regcomply.com</a></div>
				</div>
			</div>

			<div className="col-md-6 offset-2">
				<form onSubmit={handleSubmit}>

					<div>
						<p className="text-prim2 fw-bold mb-2">Category <span className="text-danger">*</span></p>

						<div className="dropdown">
							<div className="dropdown-item d-flex align-items-center cursor-pointer border p-2 px-3" id="countriesSelect" data-bs-toggle="dropdown" aria-expanded="false">
								<p className="text-dark mb-0 ms-1">{values.category}</p>
							</div>
							{errors.category && <div className="mt-2 align-items-center">
								<ErrorTag className="me-2" />
								<span className="text-danger small">{errors.category}</span>
							</div>}

							<ul className="dropdown-menu menuHeight w-100" aria-labelledby="countriesSelect">
								{
									categories?.length
										? categories?.map((category, index) => (
											<li key={index} className="dropdown-item d-flex align-items-center" onClick={() => setFieldValue("category", category)}>
												<p className={`mb-0 ${category === values.category ? "active " : ""}`}>{category}</p>
											</li>
										))
										: <p className="small p-3 mb-0">{"Loading..."}</p>
								}
							</ul>
						</div>
					</div>

					<div className="mt-4">
						<p className="text-prim2 fw-bold mb-2">I have a problem with <span className="text-danger">*</span></p>

						<div className="dropdown">
							<div className="dropdown-item d-flex align-items-center cursor-pointer border p-2 px-3" id="countriesSelect" data-bs-toggle="dropdown" aria-expanded="false">
								<p className="text-dark mb-0 ms-1">{values.title}</p>
							</div>
							{errors.title && <div className="mt-2 align-items-center">
								<ErrorTag className="me-2" />
								<span className="text-danger small">{errors.title}</span>
							</div>}

							<ul className="dropdown-menu menuHeight w-100" aria-labelledby="countriesSelect">
								{
									problems?.length
										? problems?.map((title, index) => (
											<li key={index} className="dropdown-item d-flex align-items-center" onClick={() => setFieldValue("title", title)}>
												<p className={"mb-0"}>{title}</p>
											</li>
										))
										: <p className="small p-3 mb-0">{"Loading..."}</p>
								}
							</ul>
						</div>
					</div>

					<div className="mt-4">
						<p className="text-prim2 fw-bold mb-2">Your Message <span className="text-danger">*</span></p>
						<textarea
							className="form-control" name="message"
							value={values.message} onChange={handleChange}
							placeholder={"Type your message here..."}
							style={{ height: 200 }}
						/>
						{errors.message && <div className="mt-2 align-items-center">
							<ErrorTag className="me-2" />
							<span className="text-danger small">{errors.message}</span>
						</div>}
					</div>

					<div className="d-flex align-items-center mt-5">
						<button type="submit" className="btn bg-prim text-white text-center p-2 px-3 ms-auto">
							Send message
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};
