import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { HomeAssessMentIcon } from "../../../assets";
import { SET_PAGE_TITLE } from "../../../store/constants";
import security from "../../../assets/svg/finCrime.svg";
import { Link } from "react-router-dom";

export const AssessmentHome = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch({ type: SET_PAGE_TITLE, payload: " CHOOSE AN ASSESSMENT TYPE" });
	}, []);

	return (
		<div className="mb-5 col-md-10">
			<p className="fw-bold text-prim2 fs-3 mb-5">Assessment</p>
			<div className="row">
				<div className="col-4">
					<Link className="p-5 border rounded round bg-light shadow-sm d-block highLightHover" to={"/assessment/client-type"}>
						<HomeAssessMentIcon className="mb-4" />
						<p className="text-prim2 fw-bold fs-5 mb-2">Real Estate</p>
						<p className="mb-0 text-muted"> Perform assessment on client you would like to rent and sell your real estate to. </p>
					</Link>
				</div>

				<div className="col-4">
					<Link className="p-5 border rounded round bg-light shadow-sm d-block highLightHover" to={"/assessment"}>
						<img src={security} alt="secure the icon" className="mb-4" />
						<p className="text-prim2 fw-bold fs-5 mb-2">Fincrime CRA</p>
						<p className="mb-0 text-muted"> Perform assessment on client you would like to rent and sell your real estate to. </p>
					</Link>
				</div>
			</div>
		</div>
	);
};
