import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { WhiteArrow } from "../../../../assets";
import { REFRESH_COMPANY, UPDATE_COMPANY } from "../../../../store";
import countries from "../../../../store/countries";

export const BusinessType = () => {
	const dispatch = useDispatch();
	const { regPayload: { countryCode, country } } = useSelector(state => state.auth);
	// console.log({ countries, countryCode, country });

	const { values } = useFormik({ initialValues: { country: countryCode } });

	const setCountry = (country) => {
		dispatch({
			type: UPDATE_COMPANY,
			payload: {
				country: country.country,
				countryCode: country.countryCodes
			}
		});
	};

	return (
		<div className="mainBody comp">
			<h2 className="fw-bold text-prim2">Business location</h2>
			<p className="fw-light text-muted mb-0">
				Let us know where your business is based, so that
				<br />
				we can assist you with opening your account.{" "}
			</p>

			<div className="dropdown">
				<li className="dropdown-item d-flex align-items-center cursor-pointer border mt-4 p-3" id="countriesSelect" data-bs-toggle="dropdown" aria-expanded="false">
					<img src={`https://flagcdn.com/w320/${countryCode?.toLowerCase() || "GB"}.png`} alt="flag" style={{ width: 30, height: 20 }} className="me-2" />
					<p className="text-dark mb-0 ms-1">{country || "United Kingdom"}</p>
				</li>

				<ul className="dropdown-menu menuHeight w-100" aria-labelledby="countriesSelect">
					{
						countries?.length
							? countries?.map((country, index) => (
								<li key={country.country} className="dropdown-item d-flex align-items-center" onClick={() => setCountry(country)}>
									<img src={country.flag} alt="" style={{ width: 30, height: 20 }} className="me-2" />
									<p className={`mb-0 ${country.CountryCodes === values.country ? "active " : ""}`}>{country.country}</p>
								</li>
							))
							: <p className="small p-3 mb-0">{"Loading..."}</p>
					}
				</ul>
			</div>

			<div className="d-flex align-items-center mt-5">
				<Link className="btn bg-prim text-white text-center p-3 px-4" to={countryCode === "GB" ? "/business-account/find" : "/business-account/non_uk"} onClick={() => dispatch({ type: REFRESH_COMPANY })}>
					Continue <WhiteArrow className="ms-3" />
				</Link>
			</div>
		</div>
	);
};

// other page exports
export * from "./findBusiness";
export * from "./businessDetails";
export * from "./businessAddress";
export * from "./personalDetails";
export * from "./nonUkBusinessDetails";
export * from "./nonUKBusinessAddress";
