import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetResquest } from "../../../api/useRequestProcessor";
import { CloseIcon, Filter, Plus, WhiteArrow } from "../../../assets";
import { DownloadModal, EmptyDashboard, FilterContent, Loader } from "../../../components";
import { CHANGE_FIRT_TIME_USER, SET_PAGE_TITLE } from "../../../store/constants";
import { generateUrl } from "../../../utils";
import ReactPaginate from "react-paginate";

export const Dashboard = () => {
	const initialFilterParams = {
		level: "",
		sort: "",
		status: "",
		date: "",
		q: ""
	};
	const dispatch = useDispatch();
	const { firstTimeUser } = useSelector(({ dash }) => dash);
	const [enabled, setEnabled] = useState(false);
	const navigate = useNavigate();
	const [page, setPage] = useState(1);
	const { data: dashAggregates } = useGetResquest("/results/aggregates", "aggregates", enabled);
	const [filterParam, setFilterParam] = useState(initialFilterParams);

	const query = useMemo(() =>
		generateUrl({
			...filterParam,
			page,
			limit: "10"
		}), [filterParam, page]);
	const { data: results, isLoading, isFetching, refetch } = useGetResquest(`/results?${query}`, "results", enabled);

	useEffect(() => {
		const timeout = setTimeout(() => {
			clearTimeout(timeout);
			setEnabled(true);
		}, 300);
		dispatch({ type: SET_PAGE_TITLE, payload: "ACCOUNT OVERVIEW" });
	}, []);

	useEffect(() => {
		refetch();
	}, [query, page]);

	const downloadResult = (isLocked, id) => {
		if (isLocked) {
			toast.warning("This result is locked and cannot be downloaded.");
		}
	};

	const routeChecker = (id, status) => {
		if (status === "success") navigate("/assessment/result/" + id);
		else toast.warn("Please wait while the calculations are being performed.");
	};

	const changeSort = () => {
		const sort = filterParam.sort === "asc" ? "desc" : "asc";
		setFilterParam(prev => ({ ...prev, sort }));
	};

	const handlePageClick = (event) => {
		const newpage = event.selected + 1;
		setPage(newpage);
	};

	return (
		<>
			<DownloadModal />

			{
				firstTimeUser &&
				<div className="p-5 bg-prim shadow-sm dashboardBanner d-flex justify-content-between">
					<div>
						<p className="mb-4 text-white fs-5">
							Verify people you are getting in business with by <br />
							using our risk assessment tools.
						</p>
						<Link to="/" className="text-muted text-decoration-underline">Learn more<WhiteArrow className="ms-2" /></Link>
					</div>

					<CloseIcon className="cursor-pointer" onClick={() => dispatch({ type: CHANGE_FIRT_TIME_USER })} />
				</div>
			}

			{
				((results?.results.length || Object.values(filterParam).some(Boolean)))
					? <>
						<div className="row my-5">
							<div className="col-xs-6 col-md-3 text-white">
								<div className="p-4 dashCard border shadow-sm bg-white">
									<p className="text-prim2 mb-0">Low Risk Assessment</p>
									<p className="fs-1 fw-bold mt-4 mb-3 text-prim2">{dashAggregates?.data[1]?.count ?? "..."}</p>
									{/* <p className="mb-0 text-success fs-6">5.3%</p> */}
								</div>
							</div>

							<div className="col-xs-6 col-md-3 text-white">
								<div className="p-4 dashCard border shadow-sm bg-white">
									<p className="text-prim2 mb-0">Medium Risk Assessment</p>
									<p className="fs-1 fw-bold mt-4 mb-3 text-prim2">{dashAggregates?.data[2]?.count ?? "..."}</p>
									{/* <p className="mb-0 text-success fs-6">5.3%</p> */}
								</div>
							</div>

							<div className="col-xs-6 col-md-3 text-white">
								<div className="p-4 dashCard border shadow-sm bg-white">
									<p className="text-prim2 mb-0">High Risk Assessment</p>
									<p className="fs-1 fw-bold mt-4 mb-3 text-prim2">{dashAggregates?.data[0]?.count ?? "..."}</p>
									{/* <p className="mb-0 text-success fs-6">5.3%</p> */}
								</div>
							</div>
							<div className="col-xs-6 col-md-3">
								<Link to="/assessment" className="p-4 h-100 border bg-light dashCard last justify-content-center align-items-center d-flex flex-column">
									<Plus />
									<p className="text-center mt-3 mb-0">New Assessment</p>
								</Link>
							</div>
						</div>

						<div className="my-5">
							<div className="d-flex align-items-center justify-content-between">
								<p className="text-muted fs-5 fw-bold">Activities</p>

								<div className="position-relative">
									<FilterContent
										reset={() => setFilterParam(initialFilterParams)}
										filterParam={filterParam}
										setFilterParam={setFilterParam}
									/>
								</div>

							</div>

							<div className="table-responsive bg-white rounded shadow">
								{
									isFetching
										? <div className="w-100 h-100 align-items-center justify-content-center d-flex flex-column mt-n3">
											<Loader size={"bg"} />
											<p className="text-center mt-3 text-muted">Please wait...</p>
										</div>
										: <table className="table mb-0">
											<thead>
												<tr className="text-muted">
													<th scope="col">Name</th>
													<th scope="col">Assessment </th>
													<th scope="col">Risk level</th>
													<th scope="col" >Status </th>
													<th scope="col" className="cursor-pointer" onClick={changeSort}>Date <Filter className="ms-2" /></th>
													<th scope="col"></th>
													<th scope="col"></th>
													<th scope="col"></th>
												</tr>
											</thead>
											<tbody className="border-top">
												{
													results?.results.map((result) => (
														<tr key={result._id} className={"assessmentResults"}>
															<td className="text-truncate text-prim2" onClick={() => routeChecker(result._id, result.status)}>{result.name}</td>
															<td className="text-prim2 text-capitalize" onClick={() => routeChecker(result._id, result.status)}>{result.methodology_name}</td>
															<td>
																<span className={`py-2 px-4 badge rounded small alert-${result.result_data.rating.toLowerCase() === "low" ? "success" : result.result_data.rating.toLowerCase() === "high" ? "danger" : "warning"}`}>{result.result_data?.rating}</span>
															</td>
															<td className={`text-${result.status}`}>{result.status.charAt(0).toUpperCase() + result.status.slice(1)}</td>
															<td className="text-prim2">{new Date(result.created_at).toLocaleDateString()}</td>
															<td className={`p-0 ${!result.unlocked ? "lock" : ""}`} />
															<td className={"p-0 assessmentDownload"} data-active={result.unlocked ? "true" : ""} data-bs-toggle={result.unlocked ? "modal" : ""}
																// data-bs-target={result.unlocked ? "#downloadModal" : ""}
																onClick={() => downloadResult(!result.unlocked, result._id)}
															/>
															<td className="p-0 dashEllipses" />
														</tr>
													))}
											</tbody>
										</table>
								}
							</div>
						</div>

						<div className="pagination">
							<ReactPaginate
								breakLabel="..."
								nextLabel="Next"
								nextClassName="fw-bold"
								previousClassName="fw-bold"
								onPageChange={handlePageClick}
								pageClassName={"px-2 py-1 bg-white me-2 border rounded"}
								pageRangeDisplayed={5}
								activeClassName={"border-prim2"}
								disabledClassName={"text-muted fw-normal"}
								pageCount={results?.pagination?.total_pages}
								previousLabel="Previous"
								renderOnZeroPageCount={null}
							/>
						</div>

					</>
					: (isLoading)
						? <div className="w-100 h-100 align-items-center justify-content-center d-flex flex-column mt-n3">
							<Loader size={"bg"} />
							<p className="text-center mt-3 text-muted">Please wait...</p>
						</div>
						: <EmptyDashboard />
			}
		</>
	);
};
