import React, { useState } from "react";
import PropTypes from "prop-types";
import { ErrorTag, HighRisk, LowRisk, MediumRisk, SuccessTag } from "../../assets";

export const ResultCard = ({ type, role, verificationSuccess, message, amlCheck, deceasedCheck, name }) => {
	return (
		<div className="rounded bg-light overflow-hidden mb-3">
			<div className={`border-start leftBorder border-5 border-${type === "low" ? "success" : type === "high" ? "danger" : "warning"}  p-3 d-flex align-items-center justify-content-between`}>
				<div className="">
					<p className="text-muted small"><span className="text-prim2 fw-bold h6">{name}</span> | <span className="text-capitalize">{role}</span> applicant</p>
					{amlCheck && <p className="text-muted mb-1 small">UK AML Check: <span className="text-prim2 fw-bold">{amlCheck}</span></p>}
					{deceasedCheck && <p className="text-muted mb-1 small">UK Deceased Person Check: <span className="text-prim2 fw-bold"> {deceasedCheck}</span></p>}

					<div className="mt-4">
						{
							verificationSuccess
								? <div className="d-flex align-items-center text-success">
									<SuccessTag className="me-2" />
									<p className="mb-0 small mt-1">{message}</p>
								</div>
								: <div className="d-flex align-items-center text-danger">
									<ErrorTag className="me-2" />
									<p className="mb-0 small mt-1">{message}</p>
								</div>
						}
					</div>
				</div>

				<div className="d-flex flex-column mx-5 align-items-center justify-content-center">
					{
						type === "low"
							? <LowRisk />
							: type === "high"
								? <HighRisk />
								: <MediumRisk />
					}
					<p className={`text-capitalize text-nowrap mt-2 mb-0 text-${type === "low" ? "success" : type === "high" ? "danger" : "warning"}`}>{type} risk</p>
				</div>
			</div>
		</div>
	);
};

export const PepResultCard = ({ type, role, verificationSuccess, message, category, country, positions, name }) => {
	const [showMore, setShowMore] = useState(false);

	return (
		<div className="rounded bg-light overflow-hidden mb-3">
			<div className={`border-start leftBorder border-5 border-${type === "low" ? "success" : type === "high" ? "danger" : "warning"}  p-3 d-flex align-items-center justify-content-between`}>
				<div className="">
					<p className="text-muted small"><span className="text-prim2 fw-bold h6">{name}</span> | <span className="text-capitalize">{role}</span> applicant</p>
					<p className="text-muted mb-1 small">
						{(category > 0) && <>PEP Category: <span className="text-prim2 fw-bold me-5">Tier {category}</span> </>}
						{country && <>PEP: Country:<span className="text-prim2 fw-bold"> {country}</span></>}
					</p>
					{positions?.length && <p className="text-muted mb-1 mt-3 small">Position(s): <span className="text-prim2 fw-bold">{positions.length}</span></p>}

					<ol className="text-muted">
						{
							positions?.length && positions.slice(0, showMore ? positions.length : 3)?.map((item, index) => (
								<li key={"item_" + index} className={"small"}>{item}</li>
							))
						}
					</ol>

					<div className="d-flex">
						{positions?.length > 3 && <p className={`text-${type === "low" ? "success" : type === "high" ? "danger" : "warning"} cursor-pointer mt-2`} onClick={() => setShowMore(prev => !prev)}>Show {!showMore ? "More" : "Less"}</p>}
					</div>

					<div className="mt-4">
						{
							verificationSuccess
								? <div className="d-flex align-items-center text-success">
									<SuccessTag className="me-2" />
									<p className="mb-0 small mt-1">{message}</p>
								</div>
								: <div className="d-flex align-items-center text-danger">
									<ErrorTag className="me-2" />
									<p className="mb-0 small mt-1">{message}</p>
								</div>
						}
					</div>
				</div>

				<div className="d-flex flex-column mx-5 align-items-center justify-content-center">
					{
						type === "low"
							? <LowRisk />
							: type === "high"
								? <HighRisk />
								: <MediumRisk />
					}
					<p className={`text-capitalize mt-2 mb-0 text-nowrap text-${type === "low" ? "success" : type === "high" ? "danger" : "warning"}`}>{type} risk</p>
				</div>
			</div>
		</div>
	);
};

export const SanctionResultCard = ({ type, role, verificationSuccess, message, sanctions, name }) => {
	const [showMore, setShowMore] = useState(false);

	return (
		<div className="rounded bg-light overflow-hidden mb-3">
			<div className={`border-start leftBorder border-5 border-${type === "low" ? "success" : type === "high" ? "danger" : "warning"}  p-3 d-flex align-items-center justify-content-between`}>
				<div className="">
					<p className="text-muted small"><span className="text-prim2 fw-bold h6">{name}</span> | <span className="text-capitalize">{role}</span> applicant</p>
					{sanctions?.length && <p className="text-muted mb-1 small">Sanction bodies: <span className="text-prim2 fw-bold">{sanctions.length}</span> found</p>}

					<ol className="text-muted">
						{
							sanctions?.length &&
							sanctions?.slice(0, showMore ? sanctions.length : 3)?.map((item, index) => (
								<li key={"item_" + index} className={"small"}>{item}</li>
							))
						}
					</ol>

					<div className="d-flex">
						{sanctions?.length > 3 && <p className={`text-${type === "low" ? "success" : type === "high" ? "danger" : "warning"} cursor-pointer mt-2`} onClick={() => setShowMore(prev => !prev)}>Show {!showMore ? "More" : "Less"}</p>}
					</div>

					<div className="mt-4">
						{
							verificationSuccess
								? <div className="d-flex align-items-center text-success">
									<SuccessTag className="me-2" />
									<p className="mb-0 small mt-1">{message}</p>
								</div>
								: <div className="d-flex align-items-center text-danger">
									<ErrorTag className="me-2" />
									<p className="mb-0 small mt-1">{message}</p>
								</div>
						}
					</div>
				</div>

				<div className="d-flex flex-column mx-5 align-items-center justify-content-center">
					{
						type === "low"
							? <LowRisk />
							: type === "high"
								? <HighRisk />
								: <MediumRisk />
					}
					<p className={`text-capitalize text-nowrap mt-2 mb-0 text-${type === "low" ? "success" : type === "high" ? "danger" : "warning"}`}>{type} risk</p>
				</div>
			</div>
		</div>
	);
};

export const GeoResultCard = ({ type, role, verificationSuccess, message, nationalities, residency, extras, name }) => {
	return (
		<div className="rounded bg-light overflow-hidden mb-3">
			<div className={`border-start leftBorder border-5 border-${type === "low" ? "success" : type === "high" ? "danger" : "warning"}  p-3 d-flex align-items-center justify-content-between`}>
				<div className="">
					<p className="text-muted small"><span className="text-prim2 fw-bold h6">{name}</span> | <span className="text-capitalize">{role}</span> applicant</p>

					{residency && <p className="text-muted mb-1 small">Residency: <span className="text-prim2 fw-bold">{residency}</span></p>}
					<div className="mt-2">
						{
							verificationSuccess
								? <div className="d-flex align-items-center text-success">
									<SuccessTag className="me-2" />
									<p className="mb-0 small mt-1">{message}</p>
								</div>
								: <div className="d-flex align-items-center text-danger">
									<ErrorTag className="me-2" />
									<p className="mb-0 small mt-1">{message}</p>
								</div>
						}
					</div>
					{nationalities?.length && <p className="text-muted mb-1 small mt-4">Nationality(ies): <span className="text-prim2 fw-bold">{nationalities.join(", ")}</span></p>}

					{
						extras.decision &&
						<div className={`d-flex align-items-center text-${extras.risk_rating.toLowerCase() === "low" ? "success" : extras.risk_rating.toLowerCase() === "high" ? "danger" : "warning"}`}>

							{extras.risk_rating.toLowerCase() === "low" ? <SuccessTag className="me-2" /> : extras.risk_rating.toLowerCase() === "high" ? <ErrorTag className="me-2" /> : <></>}
							<p className="mb-0 small mt-1">{extras.decision}</p>
						</div>
					}
				</div>

				<div className="d-flex flex-column mx-5 align-items-center justify-content-center">
					{
						type === "low"
							? <LowRisk />
							: type === "high"
								? <HighRisk />
								: <MediumRisk />
					}
					<p className={`text-capitalize mt-2 text-nowrap mb-0 text-${type === "low" ? "success" : type === "high" ? "danger" : "warning"}`}>{type} risk</p>
				</div>
			</div>
		</div >
	);
};

export const OtherResultCard = ({ type, role, verificationSuccess, message, name }) => {
	return (
		<div className="rounded bg-light overflow-hidden mb-3">
			<div className={`border-start leftBorder border-5 border-${type === "low" ? "success" : type === "high" ? "danger" : "warning"}  p-3 d-flex align-items-center justify-content-between`}>
				<div className="">
					<p className="text-muted small"><span className="text-prim2 fw-bold h6">{name}</span> | <span className="text-capitalize">{role}</span> applicant</p>

					<div className="mt-2">
						{
							verificationSuccess
								? <div className="d-flex align-items-center text-success">
									<SuccessTag className="me-2" />
									<p className="mb-0 small mt-1">{message}</p>
								</div>
								: <div className="d-flex align-items-center text-danger">
									<ErrorTag className="me-2" />
									<p className="mb-0 small mt-1">{message}</p>
								</div>
						}
					</div>
				</div>

				<div className="d-flex flex-column mx-5 align-items-center justify-content-center">
					{
						type === "low"
							? <LowRisk />
							: type === "high"
								? <HighRisk />
								: <MediumRisk />
					}
					<p className={`text-capitalize mt-2 text-nowrap mb-0 text-${type === "low" ? "success" : type === "high" ? "danger" : "warning"}`}>{type} risk</p>
				</div>
			</div>
		</div>
	);
};

ResultCard.propTypes = {
	type: PropTypes.string,
	data: PropTypes.object,
	verificationSuccess: PropTypes.bool,
	message: PropTypes.string,
	role: PropTypes.string,
	amlCheck: PropTypes.string,
	deceasedCheck: PropTypes.string,
	name: PropTypes.string
};

PepResultCard.propTypes = {
	type: PropTypes.string,
	data: PropTypes.object,
	verificationSuccess: PropTypes.bool,
	message: PropTypes.string,
	role: PropTypes.string,
	category: PropTypes.string,
	country: PropTypes.string,
	positions: PropTypes.array,
	name: PropTypes.string
};

SanctionResultCard.propTypes = {
	type: PropTypes.string,
	data: PropTypes.object,
	verificationSuccess: PropTypes.bool,
	message: PropTypes.string,
	role: PropTypes.string,
	name: PropTypes.string,
	sanctions: PropTypes.array
};

GeoResultCard.propTypes = {
	type: PropTypes.string,
	data: PropTypes.object,
	verificationSuccess: PropTypes.bool,
	message: PropTypes.string,
	role: PropTypes.string,
	nationalities: PropTypes.array,
	extras: PropTypes.any,
	name: PropTypes.string,
	residency: PropTypes.string
};

OtherResultCard.propTypes = {
	type: PropTypes.string,
	data: PropTypes.object,
	verificationSuccess: PropTypes.bool,
	message: PropTypes.string,
	role: PropTypes.string,
	nationalities: PropTypes.array,
	extras: PropTypes.string,
	name: PropTypes.string,
	residency: PropTypes.string
};
