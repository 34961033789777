import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import OtpInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { usePostRequest } from "../../../api/useRequestProcessor";
import { ErrorTag } from "../../../assets";
import { Input, resetPasswordSchema } from "../../../components";

export const PasswordVerification = () => {
	const { state } = useLocation();
	const navigate = useNavigate();

	const { mutate: validate, isLoading } = usePostRequest("auth/reset-password");

	const onSubmit = (values) => {
		validate(values, {
			onSuccess: (data) => {
				toast.success(data?.message);
				navigate("/");
			},
			onError: ({ error }) => {
				toast.error(error?.message);
			}
		});
	};

	const otpResend = () => {
		toast.success("Processing...");
		axios.get("auth/resend/code")
			.then((response) => {
				if (response?.data) {
					toast.success(response?.data?.message);
				} else {
					toast.error(response?.message);
				}
			}).catch(err => {
				toast.error(err.response?.data?.error?.message);
			});
	};

	useEffect(() => {
		if (!state?.mail) navigate("/");
	}, [state?.mail]);

	const { values, setFieldValue, touched, errors, handleSubmit, handleChange } = useFormik({
		initialValues: { code: "", password: "", confirm_password: "" },
		validationSchema: resetPasswordSchema,
		onSubmit
	});

	return (
		<div>
			<h2 className="fw-bold text-prim2">Verfication</h2>
			<p className="fw-light text-muted mb-5">
				Enter the code sent to{" "}
				<b className="text-prim2">
					{state?.mail}
				</b>{" "}
			</p>

			<form onSubmit={handleSubmit}>
				<OtpInput
					value={values.code}
					name={"code"}
					onChange={(e) => setFieldValue("code", e)}
					numInputs={6}
					inputStyle={{ width: "60px", height: "56px", padding: 15, borderColor: (errors.code) ? "red" : "#0a1d4f", borderWidth: 1 }}
					containerStyle={{ justifyContent: "space-between", flexDirection: "row" }}
				/>
				{touched.code && errors.code && <div className="align-items-center mt-2">
					<ErrorTag className="me-2" />
					<span className="text-danger small">{errors.code}</span>
				</div>}

				<Input
					label={"New password"}
					viewClass={"mt-4"}
					name="password"
					type={"password"}
					value={values.password}
					onChange={handleChange}
					isTouched={touched.password}
					error={errors.password}
					placeholder="********"
				/>

				<Input
					label={"Confirm new password"}
					viewClass={"mt-4"}
					name="confirm_password"
					type={"password"}
					value={values.confirm_password}
					onChange={handleChange}
					isTouched={touched.confirm_password}
					error={errors.confirm_password}
					placeholder="********"
				/>

				<button
					disabled={isLoading}
					type={"submit"}
					className="btn bg-prim text-white w-100 text-center mb-4 mt-5 p-3"
				>
					{isLoading ? "Please wait..." : "Submit"}
				</button>

				<div>
					<p className="text-muted">
						Didn’t get any code?{" "}
						<span className="text-prim2 fw-normal cursor-pointer" onClick={otpResend}>
							Send a new code
						</span>
					</p>
				</div>
			</form>
		</div>
	);
};
