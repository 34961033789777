import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Minus, Plus, store } from "../../../assets";

export const HelpHome = () => {
	const dispatch = useDispatch();
	const { faq: { general, service } } = store;
	const { values, setFieldValue, handleChange } = useFormik({
		initialValues:
		{
			searchString: "",
			general: general.map(item => ({ ...item, canShow: false })),
			service: service.map(item => ({ ...item, canShow: false }))
		}
	});

	useEffect(() => {
		dispatch({ type: "SET_PAGE_TITLE", payload: "HELP" });
	}, []);

	useEffect(() => {
		setFieldValue("general", general.filter(item => {
			const search = JSON.stringify(item).toLowerCase();
			return search.includes(values.searchString.toLowerCase());
		}));
		setFieldValue("service", service.filter(item => {
			const search = JSON.stringify(item).toLowerCase();
			return search.includes(values.searchString.toLowerCase());
		}));
	}, [values.searchString]);

	const toggleShow = (index) => {
		setFieldValue("general", values.general.filter((item, i) => {
			if (i === index) {
				item.canShow = !item.canShow;
			}; return item;
		}));
	};
	const toggleService = (index) => {
		setFieldValue("service", values.service.filter((item, i) => {
			if (i === index) {
				item.canShow = !item.canShow;
			}; return item;
		}));
	};

	return (
		<>
			<p className="text-center text-prim2 fs-4 fw-bold mb-0">Frequently Asked Questions</p>

			<div className="w-75 mx-auto">
				<input
					value={values.searchString}
					onChange={handleChange}
					name="searchString"
					placeholder="Search for a question.."
					className="noFocusWithin p-3 border rounded bg-white shadow-sm w-100 mt-4 text-prim"
				/>
			</div>

			<div className="my-5 bg-white py-5">
				<div className="p-4 bg-white shadow-sm border rounded">
					<div className="d-flex">
						<p className="text-prim2 fw-bold col-4">General</p>

						<div className="col-7 offset-1">
							{
								values.general.map((item, index) => (
									<div key={index} className={"px-3 py-4 border-bottom"}>
										<div onClick={() => toggleShow(index)} className="d-flex align-items-center justify-content-between cursor-pointer">
											<p className="text-prim2 fw-bold mb-0">{item.title}</p>
											{item.canShow ? <Plus width={20} /> : <Minus width={20} />}
										</div>

										{item.canShow && <p className="text-muted small mb-0 mt-3">{item.body}</p>}
									</div>
								))
							}
						</div>
					</div>

					<div className="d-flex mt-5">
						<p className="text-prim2 fw-bold col-4">Our Service</p>

						<div className="col-7 offset-1">
							{
								values.service.map((item, index) => (
									<div key={index} className={"mb-3 pb-3 p-3 border-bottom"}>
										<div onClick={() => toggleService(index)} className="d-flex align-items-center justify-content-between cursor-pointer">
											<p className="text-prim2 fw-bold mb-0">{item.title}</p>
											{item.canShow ? <Plus width={20} /> : <Minus width={20} />}
										</div>

										{item.canShow && <p className="text-muted small mb-0 mt-3">{item.body}</p>}
									</div>
								))
							}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
