import { useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { usePostRequest } from "../../../api/useRequestProcessor";
import { ErrorTag } from "../../../assets";
import { Input, RegisterSchema } from "../../../components";

export const Register = () => {
	const navigate = useNavigate();
	const { mutate: register, isLoading } = usePostRequest("auth/register");
	const dispatch = useDispatch();

	const onSubmit = (values) => {
		register(values, {
			onSuccess: (data) => {
				dispatch({ type: "TEST_LOGIN", payload: data.confirmation_token });
				toast.success(data?.message);

				navigate("/verification", { state: { mail: values.email, fromRoute: "register" } });
			},
			onError: ({ error }) => {
				toast.error(error.message);
			}
		});
	};

	const { values, handleChange, handleSubmit, errors, touched } = useFormik({
		initialValues: { email: "", password: "", first_name: "", last_name: "", marketing: false, terms: false },
		onSubmit,
		validationSchema: RegisterSchema
	});

	return (
		<div className="my-5">
			<h2 className="fw-bold text-prim2">Create an Account</h2>
			<p className="fw-light text-muted mb-5">
				Let’s get your journey wiith us started by creating<br />
				your regcomply account
			</p>

			<form onSubmit={handleSubmit}>
				<div className="d-flex mb-4">
					<Input
						viewClass={"w-50 pe-3"}
						label={"First name"}
						name="first_name"
						value={values.first_name}
						onChange={handleChange}
						isTouched={touched.first_name}
						error={errors.first_name}
						placeholder="John"
					/>

					<Input
						viewClass={"w-50 ps-3"}
						label={"Last name"}
						name="last_name"
						value={values.last_name}
						onChange={handleChange}
						isTouched={touched.last_name}
						error={errors.last_name}
						placeholder="Doe"
					/>
				</div>

				<Input
					label={"Email"}
					name="email"
					value={values.email}
					onChange={handleChange}
					isTouched={touched.email}
					error={errors.email}
					placeholder="example@mail.com"
				/>

				<Input
					viewClass={"mt-4"}
					label={"Password"}
					name="password"
					type={"password"}
					value={values.password}
					onChange={handleChange}
					isTouched={touched.password}
					error={errors.password}
					placeholder="********"
				/>

				<div className="d-flex align-items-center mt-4">
					<input type={"checkbox"} checked={values?.marketing} name={"marketing"} onChange={handleChange} />
					<label
						className="ms-2 small text-muted"
					>
						Yes, please keep me up to date with Regcomply.{" "}
					</label>
				</div>

				<div className="d-flex align-items-center mt-1">
					<input type={"checkbox"} checked={values?.terms} name={"terms"} onChange={handleChange} />
					<label
						className="ms-2 small text-muted"
					>
						I agree to our{" "}
						<Link className="text-prim2 fw-bold" to={"/"}>
							terms of services
						</Link>{" "}
						and{" "}
						<Link to={"/"} className="text-prim2 fw-bold">
							privacy policy
						</Link>
					</label>

				</div>

				{
					touched.terms && errors.terms && <div className="align-items-center">
						<ErrorTag className="me-2" />
						<span className="text-danger small">{errors.terms}</span>
					</div>
				}

				<button type="submit" disabled={isLoading} className="btn bg-prim text-white w-100 text-center mb-4 mt-5 p-3">
					{isLoading ? "Please wait..." : "Create Account"}
				</button>

				<p className="text-muted">
					Already have an account?{" "}
					<Link className="text-prim2 fw-normal" to="/">
						Login
					</Link>
				</p>
			</form>
		</div>
	);
};
