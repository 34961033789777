import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { AuthLayout, BusinessLayout, NonUkLayout } from "../components";
import { BusinessAddress, BusinessDetails, BusinessType, FindBusiness, ForgotPassword, Login, NONUKBusinessAddress, NONUKBusinessDetails, PasswordVerification, Register, SetToLogin, Verification } from "../pages";

export function DefaultRoutes() {
	return (
		<BrowserRouter>
			<Routes>
				{/* all routes */}

				<Route path={"/"} element={<AuthLayout />}>
					<Route index element={<Login />} />
					<Route path={"/forgot-password"} element={<ForgotPassword />} />
					<Route path={"/register"} element={<Register />} />
					<Route path={"/verification"} element={<Verification />} />
					<Route path={"/password-verification"} element={<PasswordVerification />} />

					{/* redirect to default if not found */}
					<Route path="*" element={<Navigate to={"/"} />} />
				</Route>

				{/* route without layouting */}
				<Route path={"/proceed_to_login"} element={<SetToLogin />} />

				<Route path={"/business-account"} element={<BusinessLayout />}>
					<Route index element={<BusinessType />} />
					<Route path={"/business-account/find"} element={<FindBusiness />} />
					<Route path={"/business-account/details"} element={<BusinessDetails />} />
					<Route path={"/business-account/address"} element={<BusinessAddress />} />

					{/* redirect to default if not found */}
					<Route path="*" element={<Navigate to={"/business-account"} />} />
				</Route>

				<Route path={"/business-account/non_uk"} element={<NonUkLayout />}>
					<Route path={"/business-account/non_uk"} element={<NONUKBusinessDetails />} />
					<Route path={"/business-account/non_uk/address"} element={<NONUKBusinessAddress />} />

					{/* redirect to default if not found */}
					<Route path="*" element={<Navigate to={"/business-account"} />} />
				</Route>

			</Routes>
		</BrowserRouter>
	);
}
