import React, { useEffect } from "react";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { GreenTick, WhiteArrow } from "../../../assets";
import { Input } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_ASSESSMENT_DETAILS, RESET_ASSESSMENT } from "../../../store";
import { AssessmentDetailsSchema } from "../../../components/forms/accessmentSchema";

export const AssessmentDetails = () => {
	const { values, handleChange, setFieldValue, handleSubmit, errors, touched, setValues } = useFormik({
		initialValues: {
			name: "",
			numberOfIndividual: ""
		},
		validationSchema: AssessmentDetailsSchema,
		onSubmit: () => {
			navigate("/assessment/personal-details");
		}
	});

	const navigate = useNavigate();
	const { dash } = useSelector(state => state);
	const dispatch = useDispatch();

	useEffect(() => {
		setValues({
			name: dash.assessments.name,
			numberOfIndividual: dash.assessments.count
		});
	}, []);

	useEffect(() => {
		dispatch({ type: CHANGE_ASSESSMENT_DETAILS, payload: { name: values.name, count: values.numberOfIndividual } });
	}, [values]);

	const resetStore = () => {
		dispatch({ type: RESET_ASSESSMENT });
	};

	return (
		<>
			<div className="d-flex justify-content-between align-items-center bg-white customShadow rounded px-5 py-3">
				<div className="d-flex align-items-centent">
					<GreenTick className="me-3" />
					<span className="mb-0 text-prim2 longHeight">CLIENT TYPE</span>
				</div>
				<div className="d-flex align-items-centent">
					<p className="small border border-prim2 rounded-circle optionText mb-0 text-prim2 d-flex align-items-center justify-content-center me-3"> 2 </p>
					<p className="mb-0 text-prim2 longHeight">ASSESSMENT DETAILS</p>
				</div>
				<div className="d-flex align-items-centent">
					<p className="small border border-muted rounded-circle optionText mb-0 text-muted d-flex align-items-center justify-content-center me-3"> 3 </p>
					<p className="mb-0 text-muted longHeight">APPLICANT INFORMATION</p>
				</div>
				<div className="d-flex align-items-centent">
					<p className="small border border-muted rounded-circle optionText mb-0 text-muted d-flex align-items-center justify-content-center me-3"> 4 </p>
					<p className="mb-0 text-muted longHeight">SUMMARY</p>
				</div>
			</div>

			<div className="d-flex bg-white customShadow rounded p-5 mt-5">
				<div className="col-4">
					<p className="text-prim2 fw-bold fs-4">Assessment details </p>
					<p className="text-muted small">Tell us how many number of individual you want to perform an assessment on. </p>
				</div>
				<form className="col-5 offset-2" onSubmit={handleSubmit}>
					<Input
						label={"Assessment name"}
						placeholder={"Enter assessment name"}
						value={values.name}
						onChange={handleChange}
						name="name"
						error={errors.name}
						isTouched={touched.name}
					/>

					<div className="mt-4">
						<p className="text-prim2">Number of Individual  </p>
						<div className="mt-3 d-flex">
							<p className={`p-3 px-4 border rounded shadow-sm cursor-pointer me-3 ${values.numberOfIndividual === "1" ? "border-prim2" : ""}`} onClick={() => setFieldValue("numberOfIndividual", "1")}>1</p>
							<p className={`p-3 px-4 border rounded shadow-sm cursor-pointer ${values.numberOfIndividual === "2" ? "border-prim2" : ""}`} onClick={() => setFieldValue("numberOfIndividual", "2")}>2</p>
						</div>
					</div>

					{/* navigation buttons */}
					<div className="d-flex align-items-center justify-content-end mt-5">
						<Link className="text-prim2 fw-normal" to="/assessment/client-type" onClick={resetStore}>
							Previous step
						</Link>

						<button type="submit" className="btn bg-prim text-white text-center p-3 ms-4 px-4">
							{<>Continue <WhiteArrow className="ms-3" /></>}
						</button>
					</div>
				</form>
			</div>
		</>
	);
};
