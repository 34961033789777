import * as yup from "yup";

export const AssessmentDetailsSchema = yup.object({
	name: yup
		.string()
		.required("Assessment name is required")
});

export const AssessmentApplicantSchema = yup.object({
	first_name: yup
		.string()
		.required("First name is required"),
	last_name: yup
		.string()
		.required("Last name is required"),
	other_name: yup
		.string(),
	residency: yup
		.string()
		.required("Residency is required"),
	date_of_birth: yup
		.string()
		.required("Date of birth is required"),
	nationality: yup
		.array()
		.required("Nationality is required")
		.test("isValid", "At least one country must be selected", (value) => {
			if (value.length) return true;
			else return false;
		}),
	electronic_check: yup.boolean()
});

export const AssessmentApplicantAddressSchema = yup.object({
	building_number: yup
		.string()
		.required("building name / number is required"),
	street_address: yup
		.string()
		.required("Street address is required"),
	city: yup
		.string()
		.required("City is required"),
	state: yup
		.string()
		.required("State is required"),
	post_code: yup
		.string()
		.required("Postal Code is required"),
	country: yup
		.string()
		.required("Country is required")
});
