export const store = {
	faq: {
		general: [{
			title: "What is Regcomply?",
			body: "Regcomply is a risk assessment company that helps you verify the identity and also access the risk of individual or business that you. Regcomply takes away the stress of not know who to "
		},
		{
			title: "Why should I use Regcomply?",
			body: "Regcomply is a risk assessment company that helps you verify the identity and also access the risk of individual or business that you. Regcomply takes away the stress of not know who to "
		},
		{
			title: "How does risk assesment work?",
			body: "Regcomply is a risk assessment company that helps you verify the identity and also access the risk of individual or business that you. Regcomply takes away the stress of not know who to "
		}],
		service: [{
			title: "What is Regcomply?",
			body: "Regcomply is a risk assessment company that helps you verify the identity and also access the risk of individual or business that you. Regcomply takes away the stress of not know who to "
		},
		{
			title: "Why should I use Regcomply?",
			body: "Regcomply is a risk assessment company that helps you verify the identity and also access the risk of individual or business that you. Regcomply takes away the stress of not know who to "
		},
		{
			title: "How does risk assesment work?",
			body: "Regcomply is a risk assessment company that helps you verify the identity and also access the risk of individual or business that you. Regcomply takes away the stress of not know who to "
		}]
	}
};
