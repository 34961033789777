import React from "react";
import {
	BottomRing,
	Onboarding1,
	Onboarding2,
	Onboarding3
} from "../../assets";
import "./index.css";
import PropTypes from "prop-types";

const data = [
	{
		title: "Save tangible resources",
		sub: "This methodology identifies risks in organizational areas, generates analyses that are accurate.",
		Illustration: Onboarding1
	},
	{
		title: "We provide support",
		sub: "This methodology identifies risks in organizational areas, generates analyses that are accurate.",
		Illustration: Onboarding2
	},
	{
		title: "Get started with us!",
		sub: "This methodology identifies risks in organizational areas, generates analyses that are accurate.",
		Illustration: Onboarding3
	}
];

const CarouselItem = ({ item: { Illustration, title, sub } }) => {
	return (
		<div className="d-flex flex-column">
			<div className="px-2 d-flex flex-column">
				<Illustration className="mx-auto d-flex w-75" />
				<p className="mb-0 text-white fw-bold h3 text-center mt-5">
					{title}
				</p>
				<p className="mb-0 text-white mt-3 w-75 text-center mx-auto">
					{sub}
				</p>
			</div>

			<BottomRing className="btmRing" />
		</div>
	);
};

export const Carousel = () => {
	return (
		<div className="carousel-body">
			{data.map((item) => (
				<CarouselItem key={Math.random()} item={item} />
			))}
		</div>
	);
};

CarouselItem.propTypes = {
	item: PropTypes.shape({
		Illustration: PropTypes.elementType,
		title: PropTypes.string,
		sub: PropTypes.string
	})
};
