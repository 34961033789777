// import axios from "axios";
import { REFRESH_COMPANY } from "..";
import { LOGIN, LOGOUT, TEST_LOGIN, UPDATE_COMPANY } from "../constants";
// import countries from "../countries";

// console.log({ countryData });

const initialState = {
	isLoggedIn: null,
	profile: null,
	token: null,
	testToken: null,
	// countries: countryData,
	pageTitle: "",
	regPayload: {
		business_name: "",
		country: "United Kingdom",
		company_number: "",
		company_status: "",
		sic_description: "",
		sic_description_extra: "",
		street_address1: "",
		street_address2: "",
		state: "",
		post_code: "",
		countryCode: "GB"
	}
};

const AuthReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN:
			return {
				...state,
				token: action.payload,
				regPayload: initialState.regPayload,
				isLoggedIn: true,
				testToken: null
			};
		case TEST_LOGIN:
			return {
				...state,
				testToken: action.payload
			};
		case UPDATE_COMPANY:
			return {
				...state,
				regPayload: { ...state.regPayload, ...action.payload }
			};
		case REFRESH_COMPANY:
			return {
				...state,
				regPayload: { ...initialState.regPayload, country: state.regPayload.country, countryCode: state.regPayload.countryCode }
			};
		case LOGOUT:
			// axios.defaults.headers.Authorization = "";
			return initialState;
		case "SET_PAGE_TITLE":
			return { ...state, pageTitle: action.payload };
		default:
			return state;
	}
};

export default AuthReducer;
