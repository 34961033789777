
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { CloseIcon, ErrorTag, GreenTick, WhiteArrow } from "../../../assets";
import { CountryDropdown, Input } from "../../../components";
import { AssessmentApplicantSchema } from "../../../components/forms/accessmentSchema";
import { SET_PAGE_TITLE, CHANGE_ASSESSMENT_APPLICANT_2 } from "../../../store/constants";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import countries from "../../../store/countries";

/* eslint-disable camelcase */
export const PersonalAssessmentDetailsCoApplicant = () => {
	const dispatch = useDispatch();

	const { values, handleChange, handleSubmit, errors, touched, setValues, setFieldValue } = useFormik({
		initialValues: {
			first_name: "",
			last_name: "",
			other_name: "",
			residency: "",
			date_of_birth: "",
			nationality: [],
			electronic_check: false
		},
		validationSchema: AssessmentApplicantSchema,
		onSubmit: (values) => {
			// console.log({ values });
			dispatch({ type: CHANGE_ASSESSMENT_APPLICANT_2, payload: values });
			navigate(values.residency.toLowerCase().includes("united kingdom") ? "/assessment/find-address/co-applicant" : "/assessment/residential-address/co-applicant");
		}
	});
	const [searchString, setSearchString] = useState("");
	const [sorted, setSorted] = useState([]);
	const navigate = useNavigate(); //eslint-disable-line
	const { dash } = useSelector(state => state);
	// const { countries } = useSelector(state => state.auth);

	useEffect(() => {
		dispatch({ type: SET_PAGE_TITLE, payload: "CREATE ASSESSMENT" });
	}, []);

	const setNationality = (country) => {
		const nationalities = values.nationality;
		const index = nationalities.findIndex(item => item === country);

		if (index === -1) nationalities.push(country); // not found
		else nationalities.splice(index, 1);

		nationalities.sort();
		setFieldValue("nationality", nationalities);
	};

	useEffect(() => {
		// console.log({ dash });
		const { address, ...values } = dash.assessments.applicant[1];
		setValues(values);
	}, [dash]);

	useEffect(() => {
		const filtered = countries.filter(country => {
			return country.country.toLowerCase().includes(searchString.toLowerCase());
		});
		setSorted(filtered);
	}, [searchString]);

	return (
		<>
			<div className="d-flex justify-content-between align-items-center bg-white customShadow rounded px-5 py-3">
				<div className="d-flex align-items-centent">
					<GreenTick className="me-3" />
					<span className="mb-0 text-prim2 longHeight">CLIENT TYPE</span>
				</div>

				<div className="d-flex align-items-centent">
					<GreenTick className="me-3" />
					<p className="mb-0 text-prim2 longHeight">ASSESSMENT DETAILS</p>
				</div>

				<div className="d-flex align-items-centent">
					<p className="small border border-prim2 rounded-circle optionText mb-0 text-prim2 d-flex align-items-center justify-content-center me-3"> 3 </p>
					<p className="mb-0 text-prim2 longHeight">APPLICANT INFORMATION</p>
				</div>

				<div className="d-flex align-items-centent">
					<p className="small border border-muted rounded-circle optionText mb-0 text-muted d-flex align-items-center justify-content-center me-3"> 4 </p>
					<p className="mb-0 text-muted longHeight">SUMMARY</p>
				</div>
			</div>

			<div className="d-flex bg-white customShadow rounded p-5 mt-5">
				<div className="col-4">
					<p className="text-muted fw-bold">Co-applicant</p>
					<p className="text-prim2 fw-bold fs-4">Personal details </p>
					<p className="text-muted small">Let us know about the personal detail of the first applicant you want to perform an assessment on. </p>
				</div>

				<form className="col-5 offset-2" onSubmit={handleSubmit}>
					<div className="d-flex justify-content-between mt-4">
						<Input
							placeholder={"first name"}
							viewClass={"w-50 me-3"}
							label={"First name"}
							name={"first_name"}
							value={values.first_name}
							onChange={handleChange}
							error={errors.first_name}
							isTouched={touched.first_name}
						/>
						<Input
							placeholder={"last name"}
							viewClass={"w-50 ms-3"}
							label={"Last name"}
							name={"last_name"}
							value={values.last_name}
							onChange={handleChange}
							error={errors.last_name}
							isTouched={touched.last_name}
						/>
					</div>
					<div className="d-flex justify-content-between mt-4">
						<Input
							placeholder={"Maiden, middle etc (optional)"}
							label={"Other name(s)"}
							viewClass={"w-50 me-3"}
							name={"other_name"}
							value={values.other_name}
							onChange={handleChange}
							error={errors.other_name}
							isTouched={touched.other_name}
						/>

						<div className="noAnimate w-50 ms-3">
							<p className="fw-bold text-prim2 mb-0">Date of Birth</p>

							<DatePicker
								onChange={(val) => setFieldValue("date_of_birth", val)}
								value={values.date_of_birth}
								className={"mt-2 p-2 border rounded w-100 calendaar"}
							/>
							{touched.date_of_birth && errors.date_of_birth && <div className="mt-2 align-items-center">
								<ErrorTag className="me-2" />
								<span className="text-danger small">{errors.date_of_birth}</span>
							</div>}
						</div>
					</div>

					<CountryDropdown
						label={"Country of residency"}
						value={values?.residency}
						setValue={(val) => setFieldValue("residency", val)}
					/>

					<div className="d-flex flex-column justify-content-start mt-4">
						<p className="fw-bold text-prim2 mb-2">Nationality</p>
						<div className="dropdown mt-0">
							<li className="dropdown-item d-flex align-items-center cursor-pointer border p-3 flex-wrap" id="countriesSelect" data-bs-toggle="dropdown" aria-expanded="false">
								{
									values?.nationality.length
										? values?.nationality.map((item) =>
											(<p key={item} className="text-white bg-prim me-2 p-2 px-3 rounded mb-0 mt-2">{item} <CloseIcon className="ms-3 cursor-pointer" width={15} onClick={() => setNationality(item)} /></p>))
										: <p className="mb-0 text-muted">click to select</p>
								}
							</li>

							<ul className="dropdown-menu menuHeight w-100" aria-labelledby="countriesSelect">
								<li className={"dropdown-item d-flex align-items-center"}>
									<input
										value={searchString} onChange={(e) => setSearchString(e.target.value)} autoFocus
										placeholder={"search countries..."} className={"w-100 p-2 border"}
									/>
								</li>
								{
									sorted?.length
										? sorted?.map((country, index) => (
											<li key={index} className="dropdown-item d-flex align-items-center" onClick={() => setNationality(country.country)}>
												<p className={"mb-0"}>{country.country}</p>
											</li>))
										: <p className="small p-3 mb-0">{"Loading..."}</p>
								}
							</ul>

							{touched.nationality && errors.nationality && <div className="mt-2 align-items-center">
								<ErrorTag className="me-2" />
								<span className="text-danger small">{errors.nationality}</span>
							</div>}
						</div>
					</div>

					{values.residency.toLowerCase() === "united kingdom" && <div className="d-flex align-items-center mt-4">
						<input
							type={"checkbox"}
							value={values.electronic_check}
							name={"electronic_check"}
							onChange={handleChange}
						/>
						<label
							className="ms-2 small text-muted"
						>
							Perform electronic verification {" "}
						</label>
					</div>}

					{/* navigation buttons */}
					<div className="d-flex align-items-center justify-content-end mt-5">
						<Link className="text-prim2 fw-normal" to="/assessment/residential-address">
							Previous step
						</Link>

						<button type="submit" className="btn bg-prim text-white text-center p-3 ms-4 px-4">
							{<>Continue <WhiteArrow className="ms-3" /></>}
						</button>
					</div>
				</form>
			</div>
		</>
	);
};
