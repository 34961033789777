import * as yup from "yup";

export const otpSchema = yup.object({
	code: yup
		.string()
		.required("Token is required")
		.test("isValid", "Invalid verification code", (value) => {
			if (Number(value) && value.length === 6) return true;
			else return false;
		})
});

export const resetPasswordSchema = yup.object().shape({
	code: yup
		.string()
		.required("Token is required")
		.test("isValid", "Invalid verification code", (value) => {
			if (Number(value) && value.length === 6) return true;
			else return false;
		}),
	password: yup
		.string()
		.required("Password is required")
		.test("isValid", "Minimum of 8 characters", (value) => {
			if (value?.length < 8) return false;
			else return true;
		})
		.test("isEqual", "Passwords do not match", (value, ctx) => {
			if (value === ctx.parent.confirm_password) return true;
			else return false;
		}),
	confirm_password: yup
		.string()
		.required("Confirm password is required")
		.test("isValid", "Minimum of 8 characters", (value) => {
			if (value?.length < 8) return false;
			else return true;
		})
		.test("isEqual", "Passwords do not match", (value, ctx) => {
			if (value === ctx.parent.password) return true;
			else return false;
		})
});
