import React from "react";
import { Link } from "react-router-dom";
import { PerformAssessentIllustration } from "../../assets";

export const EmptyDashboard = () => {
	return <div className="mt-5 pt-5 d-flex flex-column align-items-center">
		<PerformAssessentIllustration />
		<p className="mt-5 mb-0 fw-bold fs-5 text-center text-prim2">Start by performing an assessment.</p>
		<p className="text-muted text-center small"> You are just a click away from making  your first assessment.</p>

		<Link className="btn bg-prim2 text-white p-3 px-5 mt-4" to={"/assessment"}>
            Perform assessment
		</Link>
	</div>;
};
