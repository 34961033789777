import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { configureStore } from "../store";
import { LOADING, LOGOUT } from "../store/constants";
const store = configureStore();

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

const handleErrorTypeCheck = (error, dispatch) => {
	if (error?.response) {
		if (error.response.status === 401 && error.config.headers.Authorization && store.getState()?.auth?.isLoggedIn) {
			dispatch({ type: LOGOUT });
		}
		const errorMessage = {
			status: error.response.status,
			...error.response.data
		};
		throw errorMessage;
	} else {
		const errorMessage = { message: error.message };
		throw errorMessage;
	}
};

/**
 *
 * @param {string} url
 * @param {string} queryName
 * @param {boolean} enabled
 */

export const useGetResquest = (url, queryName, enabled = true, config = {}) => {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();
	const data = queryClient.getQueryData(queryName);

	return useQuery(
		queryName,
		async () => {
			if (!data) {
				dispatch({ type: LOADING, payload: true });
			}
			return axios
				.get(url)
				.then((res) => {
					// console.log(res.data);
					return res.data;
				})
				.catch((err) => handleErrorTypeCheck(err, dispatch));
		},
		{
			onError: () => {
				// console.log("error handled by them");
				// console.log(error);
				// toast.error(error.message)
			},
			onSettled: () => {
				dispatch({ type: LOADING, payload: false });
			},
			enabled,
			...config
		}
	);
};

/**
 *
 * @param {string} url
 * @param {string|Array} queryNameToInvalidate
 */

export const usePutRequest = (url, queryNameToInvalidate) => {
	const queryClient = useQueryClient();
	const dispatch = useDispatch();
	return useMutation(
		(payload) => {
			dispatch({ type: LOADING, payload: true });
			return axios
				.put(url, payload, { headers: { "Content-Type": "application/json" } })
				.then((res) => res.data)
				.catch((err) => handleErrorTypeCheck(err, dispatch));
		},
		{
			onSuccess: () =>
				queryClient.invalidateQueries(queryNameToInvalidate),
			onError: () => {

				// console.error(error);
			},
			onSettled: () => {
				dispatch({ type: LOADING, payload: false });
			}
		}
	);
};

/**
 *
 * @param {string} url
 * @param {string|Array} queryNameToInvalidate
 */

export const usePostRequest = (url, queryNameToInvalidate) => {
	const queryClient = useQueryClient();
	const dispatch = useDispatch();
	return useMutation(
		(payload) => {
			dispatch({ type: LOADING, payload: true });
			return axios
				.post(url, payload)
				.then((res) => res.data)
				.catch((err) => handleErrorTypeCheck(err, dispatch));
		},
		{
			onSuccess: () =>
				queryClient.invalidateQueries(queryNameToInvalidate),
			onError: () => {
				// console.error(error);
			},
			onSettled: () => {
				dispatch({ type: LOADING, payload: false });
			}
		}
	);
};

/**
 *
 * @param {string} url
 * @param {string|Array} queryNameToInvalidate
 */
export const useDeleteRequest = (url, queryNameToInvalidate) => {
	const queryClient = useQueryClient();
	const dispatch = useDispatch();
	return useMutation(
		(payload) => {
			dispatch({ type: LOADING, payload: true });
			return axios
				.delete(url, { data: payload })
				.then((res) => res.data)
				.catch((err) => handleErrorTypeCheck(err, dispatch));
		},
		{
			onSuccess: () =>
				queryClient.invalidateQueries(queryNameToInvalidate),
			onError: () => {
				// console.log(error);
				// toast.error(error.message)
			},
			onSettled: () => {
				dispatch({ type: LOADING, payload: false });
			}
		}
	);
};

export const usePatchRequest = (url, queryNameToInvalidate) => {
	const queryClient = useQueryClient();
	const dispatch = useDispatch();
	return useMutation(
		(payload) => {
			dispatch({ type: LOADING, payload: true });
			return axios
				.patch(url, payload)
				.then((res) => res.data)
				.catch((err) => handleErrorTypeCheck(err, dispatch));
		},
		{
			onSuccess: () =>
				queryClient.invalidateQueries(queryNameToInvalidate),
			onError: () => {
				// console.log(error);
				// toast.error(error.message)
			},
			onSettled: () => {
				dispatch({ type: LOADING, payload: false });
			}
		}
	);
};
