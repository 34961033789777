import { bool, func } from "prop-types";
import React from "react";
import { EmptyIllustration, Ilustration1, Ilustration2, Ilustration3 } from "../../../assets";

export const EmptyResult = ({ unlockResult, disabled }) => {
	return (
		<div className="d-flex flex-column align-items-center mx-auto m-5" style={{ maxWidth: 300 }}>
			<EmptyIllustration />

			<p className="fw-bold text-prim2 mt-4 mb-3">Unlock full report</p>
			<Ilustration1 className="mb-1" />
			<Ilustration2 className="mb-1" />
			<Ilustration3 />

			<button className="btn py-3 bg-prim2 w-100 text-white mt-4" onClick={unlockResult} disabled={disabled}>{disabled ? "Please wait..." : "Unlock report for 1 credit"}</button>
		</div>
	);
};

EmptyResult.propTypes = { unlockResult: func.isRequired, disabled: bool };
