import React from "react";
import { Link } from "react-router-dom";
import { BlueLogo, TargetIllustration } from "../../../assets";

export const SetToLogin = () => {
	return (
		<div className="w-100 h-100 d-flex align-items-center justify-content-center">
			<BlueLogo className="position-absolute" style={{ left: 70, top: 70 }} />

			<div className="m-auto d-flex flex-column align-items-center">
				<TargetIllustration />
				<p className="mb-2 text-prim2 text-center mt-4 fw-bold">You’re all set!</p>
				<p className="text-center text-muted small">Your account as been created, you can log in <br />to your account now</p>

				<Link className="btn bg-prim2 text-white text-center p-3 px-5 mt-4 small" to={"/"} >
					Login
				</Link>
			</div>
		</div>
	);
};
