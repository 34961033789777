import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { GreenTick, LocationIcon, WhiteArrow } from "../../../assets";
import { SET_PAGE_TITLE, CHANGE_ASSESSMENT_APPLICANT_2 } from "../../../store/constants";

export const FindAssessmentAddressCoApplicant = () => {
	const dispatch = useDispatch();
	const [value, setValue] = useState(""); //eslint-disable-line
	const [suggestions, setSuggestions] = useState([]);
	const [show, setShow] = useState(false);
	const addressRef = useRef();
	const dash = useSelector(state => state.dash);

	useEffect(() => {
		dispatch({ type: SET_PAGE_TITLE, payload: "CREATE ASSESSMENT" });
	}, []);

	useEffect(() => {
		const timeout = setTimeout(async () => {
			await axios.get(`https://api.getAddress.io/autocomplete/${value}?api-key=${process.env.REACT_APP_ADDRESS_KEY}`)
				.then(res => {
					setSuggestions(res.data.suggestions);
					clearTimeout(timeout);
				});
		}, 300);

		return () => clearTimeout(timeout);
	}, [value]);

	const selectAddress = (item) => {
		setValue(item.address);
		setShow(false);
		fetchAddressDetails(item.id);
	};

	const fetchAddressDetails = async (id) => {
		await axios.get(`https://api.getAddress.io/get/${id}?api-key=${process.env.REACT_APP_ADDRESS_KEY}`)
			.then(res => {
				const address = {
					building_number: res.data.building_number,
					street_address: res.data.thoroughfare,
					city: res.data.town_or_city,
					state: res.data.country,
					post_code: res.data.postcode,
					country: dash.assessments.applicant[0].residency
				};
				dispatch({ type: CHANGE_ASSESSMENT_APPLICANT_2, payload: { address } });
			});
	};

	return (
		<>
			<div className="d-flex justify-content-between align-items-center bg-white customShadow rounded px-5 py-3">
				<div className="d-flex align-items-centent">
					<GreenTick className="me-3" />
					<span className="mb-0 text-prim2 longHeight">CLIENT TYPE</span>
				</div>

				<div className="d-flex align-items-centent">
					<GreenTick className="me-3" />
					<p className="mb-0 text-prim2 longHeight">ASSESSMENT DETAILS</p>
				</div>

				<div className="d-flex align-items-centent">
					<p className="small border border-prim2 rounded-circle optionText mb-0 text-prim2 d-flex align-items-center justify-content-center me-3"> 3 </p>
					<p className="mb-0 text-prim2 longHeight">APPLICANT INFORMATION</p>
				</div>

				<div className="d-flex align-items-centent">
					<p className="small border border-muted rounded-circle optionText mb-0 text-muted d-flex align-items-center justify-content-center me-3"> 4 </p>
					<p className="mb-0 text-muted longHeight">SUMMARY</p>
				</div>
			</div>

			<div className="d-flex bg-white customShadow rounded p-5 mt-5">
				<div className="col-4">
					<p className="text-muted fw-bold">Co-applicant</p>
					<p className="text-prim2 fw-bold fs-4">Find applicant address  </p>
					<p className="text-muted small">Let’s help you find their residential address.      </p>
				</div>

				<div className="col-5 offset-2">
					<div className="d-flex mt-2 p-3 border rounded align-items-center">
						<LocationIcon className="me-3" />

						<div className="d-flex flex-column w-100 mapView position-relative">
							<input
								ref={addressRef}
								autoFocus={true}
								onFocus={() => setShow(true)}
								className="p-1 border-0 noFocusWithin rounded"
								onChange={e => setValue(e.target.value)}
								value={value}
							/>
							<ul className="position-absolute w-100 bg-white shadow-sm border overflow-auto px-0" style={{ top: "100%", maxHeight: 350 }}>
								{show &&
									suggestions.map(item => (
										<li key={item.id} className="d-flex align-items-center p-2 cursor-pointer w-100 hoverClass" onClick={() => selectAddress(item)}>
											<p className="mb-0">{item.address}</p>
										</li>
									))
								}
							</ul>
						</div>
					</div>

					{/* navigation buttons */}
					<div className="d-flex align-items-center justify-content-end mt-5">
						<Link className="text-prim2 fw-normal" to="/assessment/personal-details/co-applicant">
							Previous step
						</Link>

						<Link type="submit" className="btn bg-prim text-white text-center p-3 ms-4 px-4" to={"/assessment/residential-address/co-applicant"}>
							{<>Continue <WhiteArrow className="ms-3" /></>}
						</Link>
					</div>
				</div>
			</div>
		</>
	);
};
