import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Timer } from "../../../assets";
import { RESET_ASSESSMENT } from "../../../store";

export const SubmitSuccess = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch({ type: RESET_ASSESSMENT });
	}, []);

	return (
		<div className="d-flex justify-content-center align-items-center h-100 mt-n5">
			<div className="d-flex flex-column align-items-center w-50 mx-auto">
				<Timer />
				<p className="fw-bold text-prim2 text-center mt-3"> Your assessment is now being proccessed.</p>
				<p className="text-muted mb-0 text-center"> This may take up to 3 minutes to complete, you will receive a notification once your assessment is ready.</p>

				<button className="btn bg-prim2 text-white p-3 px-5 mt-4" onClick={() => navigate("/", { replace: true })}>Back to dashboard</button>
			</div>
		</div>
	);
};
