import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { usePostRequest } from "../../../api/useRequestProcessor";
import { ErrorTag } from "../../../assets";
import { otpSchema } from "../../../components";
import { LOGIN } from "../../../store";

export const Verification = () => {
	const { state } = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { mutate: validate, isLoading } = usePostRequest("auth/confirm-registration");
	const { mutate: login, isLoading: logginIn } = usePostRequest("auth/confirm-login");
	const [loading, setLoading] = useState(false);

	const onSubmit = (values) => {
		setLoading(true);
		if (state.fromRoute === "login") {
			login(values, {
				onSuccess: (response) => {
					if (!response.completed_profile) {
						dispatch({ type: "TEST_LOGIN", payload: response?.access_token });
						navigate("/business-account");
						return;
					}

					dispatch({ type: LOGIN, payload: response?.access_token });
					navigate("/");
				},
				onError: ({ error }) => {
					toast.error(error?.message);
				}
			});
		} else if (state.fromRoute === "register") {
			validate(values, {
				onSuccess: (response) => {
					toast.success("Verification successful!");
					dispatch({ type: "TEST_LOGIN", payload: response?.access_token });
					navigate("/business-account");
				},
				onError: ({ error }) => {
					toast.error(error?.message);
				}
			});
		}
		return false;
	};

	const otpResend = () => {
		toast.success("Processing...");
		axios.get("auth/resend/code")
			.then((response) => {
				if (response?.data) {
					toast.success(response?.data?.message);
				} else {
					toast.error(response?.message);
				}
			})
			.catch(err => {
				toast.error(err.response?.data?.error?.message);
			});
	};

	const { values, setFieldValue, touched, errors, handleSubmit } = useFormik({
		initialValues: { code: "" },
		validationSchema: otpSchema,
		onSubmit
	});

	useEffect(() => {
		if (!state?.mail) navigate("/");
	}, [state?.mail]);

	return (
		<div>
			<h2 className="fw-bold text-prim2">Verfication</h2>
			<p className="fw-light text-muted mb-5">
				Enter the code sent to{" "}
				<b className="text-prim2">
					{state?.mail}
				</b>{" "}
			</p>

			<form onSubmit={handleSubmit}>
				<OtpInput
					shouldAutoFocus
					value={values.code}
					name={"code"}
					onChange={(e) => setFieldValue("code", e)}
					numInputs={6}
					inputStyle={{ width: "60px", height: "56px", padding: 15, borderColor: (errors.code) ? "red" : "#0a1d4f", borderWidth: 1 }}
					containerStyle={{ justifyContent: "space-between", flexDirection: "row" }}
				/>
				{touched.code && errors.code && <div className="align-items-center mt-2">
					<ErrorTag className="me-2" />
					<span className="text-danger small">{errors.code}</span>
				</div>}

				<button
					disabled={isLoading}
					type={"submit"}
					className="btn bg-prim text-white w-100 text-center mb-4 mt-5 p-3"
				>
					{(isLoading || logginIn || loading) ? "Please wait..." : "Submit"}
				</button>

				<div>
					<p className="text-muted">
						Didn’t get any code?{" "}
						<span className="text-prim2 fw-normal cursor-pointer" onClick={otpResend}>
							Send a new code
						</span>
					</p>
				</div>
			</form>
		</div>
	);
};

export * from "./resetPasswordVerification";
