import React from "react";
import { EmptySearch } from "../../assets";

export const EmptyState = () => {
	return (
		<div className="d-flex flex-column align-items-center justify-content-center py-5 my-5">
			<EmptySearch />
			<p className="fw-bold text-prim2 mt-4 mb-1">No Result Found</p>
			<p className="text-muted mb-0">
                Please check spelling or try a different name.
			</p>
		</div>
	);
};
