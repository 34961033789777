import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { WhiteArrow } from "../../../../assets";
import { Input } from "../../../../components";

export const NONUKBusinessDetails = () => {
	const navigate = useNavigate();
	const { regPayload } = useSelector(state => state.auth);
	const dispatch = useDispatch();

	const { values, handleChange, touched, errors, setValues } = useFormik(
		{ initialValues: { company_number: "" } }
	);

	const handleSubmit = (e) => {
		e.preventDefault();

		dispatch({ type: "UPDATE_COMPANY", payload: values });
		navigate("/business-account/non_uk/address");
	};

	useEffect(() => {
		setValues({ ...values, ...regPayload });
		// console.log(regPayload);
	}, [regPayload]);

	return (
		<div className="mainBody comp overflow-auto">
			<h2 className="fw-bold text-prim2">Business details </h2>
			<p className="fw-light text-muted mb-5">
				Let us know about your business, so we can get you started.
			</p>

			<form onSubmit={handleSubmit}>
				<Input
					label={"Business name"}
					name="business_name"
					value={values.business_name}
					required
					onChange={handleChange}
					isTouched={touched.business_name}
					error={errors.business_name}
					placeholder=""
				/>

				<Input
					viewClass={"mt-4"}
					label={"Business number"}
					name="company_number"
					value={values.company_number}
					onChange={handleChange}
					isTouched={touched.company_number}
					error={errors.company_number}
					placeholder=""
				/>

				<div className="d-flex align-items-center mt-5">
					<Link className="text-prim2 fw-normal" to={"/business-account"}>
						Previous step
					</Link>

					<button type={"submit"} className="btn bg-prim text-white text-center p-3 ms-4 px-4">
						Continue <WhiteArrow className="ms-3" />
					</button>
				</div>
			</form>
		</div>
	);
};
