export const LOGIN = "LOGIN";
export const TEST_LOGIN = "TEST_LOGIN";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const SET_PAGE_TITLE = "SET_PAGE_TITLE";
export const CHANGE_FIRT_TIME_USER = "CHANGE_FIRT_TIME_USER";
export const CHANGE_ASSESSMENT_DETAILS = "CHANGE_ASSESSMENT_DETAILS";
export const CHANGE_ASSESSMENT_APPLICANT_1 = "CHANGE_ASSESSMENT_APPLICANT_1";
export const CHANGE_ASSESSMENT_APPLICANT_2 = "CHANGE_ASSESSMENT_APPLICANT_2";
export const REFRESH_COMPANY = "REFRESH_COMPANY";
export const RESET_ASSESSMENT = "RESET_ASSESSMENT";
export const SIGNUP = "SIGNUP";
export const LOGOUT = "LOGOUT";
export const LOADING = "LOADING";
