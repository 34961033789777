import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useLocation } from "react-router-dom";
import waves from "../../assets/svg/authWaves.svg";
import { ActiveDashboard, InActiveHelp, DashLogo, InActiveAccessment, InActiveDashboard, InActiveSupport, ActiveAccessment, ActiveHelp, ActiveSupport, Notification, Avatar, LockedIcon } from "../../assets";
import { useGetResquest } from "../../api/useRequestProcessor";

export function MainLayout() {
	const { pathname } = useLocation();
	const index = pathname.split("/")[1];
	const dispatch = useDispatch();
	const [enabled, setEnabled] = useState(false);
	const { data, isLoading } = useGetResquest("/users", "profile", enabled);
	const { data: balance } = useGetResquest("/users/balance", "profile-balance", enabled);

	const { pageTitle } = useSelector(state => state.dash);

	useEffect(() => {
		const timeout = setTimeout(() => {
			clearTimeout(timeout);
			setEnabled(true);
		}, 300);
	}, []);

	return (
		<div className="d-flex">
			<aside className="d-none d-md-flex col-md-2 bg-prim py-5">
				<div className="d-flex flex-column align-items-center mx-auto px-3">
					<DashLogo />

					<Link className="d-flex flex-column align-items-center mt-5 pt-5" to="/">
						{index === "" ? <ActiveDashboard /> : <InActiveDashboard />}
						<p className={`text-${index === "" ? "white" : "muted"} mt-3 mb-0`}>Dashboard</p>
					</Link>

					<Link to={"/assessment"} className="d-flex flex-column align-items-center mt-5">
						{index === "assessment" ? < ActiveAccessment /> : <InActiveAccessment />}
						<p className={`text-${index === "assessment" ? "white" : "muted"} mt-3 mb-0`}>Assesment</p>
					</Link>

					<Link to={"/support"} className="d-flex flex-column align-items-center mt-5">
						{index === "support" ? < ActiveSupport /> : <InActiveSupport />}
						<p className={`text-${index === "support" ? "white" : "muted"} mt-3 mb-0`}>Support</p>
					</Link>

					<Link to={"/help"} className="d-flex flex-column align-items-center mt-5">
						{index === "help" ? < ActiveHelp /> : <InActiveHelp />}
						<p className={`text-${index === "help" ? "white" : "muted"} mt-3 mb-0`}>Help</p>
					</Link>
				</div>

				<img src={waves} className="waves" />
			</aside>

			<main className="">
				<div className="container-fluid px-5 p-4 d-flex justify-content-between align-items-center border-bottom">
					<p className="mb-0 fw-bold text-prim2 d-none d-md-flex">{pageTitle}</p>

					<div className="d-flex align-items-center">
						{balance && <div className="position-relative me-4">
							<LockedIcon />
							<div className={`align-self-center position-absolute balanceCount rounded-circle text-white d-flex justify-content-center align-items-center ${balance?.data?.balance ? "bg-success" : "bg-danger"}`}>
								<p className="align-self-center mb-0">{balance?.data?.balance > 100 ? "100+" : balance?.data?.balance}</p>
							</div>
						</div>}

						<Notification className="me-4" />

						<div className="dropdown">
							<div className="d-none dropdown-toggle d-md-flex align-items-center cursor-pointer" id="profileactions" data-bs-toggle="dropdown" aria-expanded="false">
								<img src={Avatar} width={40} height={40} className="rounded-circle border" />

								<p className="mb-0 px-3 fw-bold fs-6 text-prim2 align-self-center">{!isLoading ? data?.data?.first_name : "..."} {isLoading ? "..." : data?.data?.last_name}</p>
							</div>

							<div className="d-flex align-items-center cursor-pointer d-md-none ms-auto" id="profileactions" data-bs-toggle="dropdown" aria-expanded="false">
								<img src={Avatar} width={40} height={40} className="rounded-circle border" />
							</div>

							<ul className="dropdown-menu" aria-labelledby="profileactions">
								<li><Link className="dropdown-item mb-0" to="/account" >Account</Link></li>
								<li><p className="dropdown-item mb-0" onClick={() => dispatch({ type: "LOGOUT" })}>Logout</p></li>
							</ul>
						</div>

					</div>
				</div>

				<div className="p-md-5 h-100 container overflow-auto">
					<Outlet />
				</div>
			</main>
		</div>
	);
};
