import * as yup from "yup";

export const loginSchema = yup.object({
	email: yup.string().email("Enter a valid email").required("Email is required"),
	password: yup
		.string()
		.required("Password is required")
		.test("isValid", "Minimum of 8 characters", (value) => {
			if (value?.length < 8) return false;
			else return true;
		})
});

export const BusinessDetailsSchema = yup.object({
	email: yup.string().email("Enter a valid email").required("Email is required"),
	password: yup
		.string()
		.required("Password is required")
		.test("isValid", "Minimum of 8 characters", (value) => {
			if (value?.length < 8) return false;
			else return true;
		})
});

export const emailValidation = yup.object(
	{ email: yup.string().email("Enter a valid email").required("Email is required") }
);
