import React, { useState } from "react";
import { ErrorTag, EyeClosed } from "../../assets";
import PropTypes from "prop-types";

export const Input = ({ label, isTouched, error, handleChange, viewClass, textStyle, inputstyle, type, ...rest }) => {
	const [showPassword, setShowPassword] = useState(false);
	return (
		<div className={`d-flex flex-column ${viewClass}`}>
			<label className={"fw-bold text-prim2 " + textStyle}>{label}</label>
			<div className={`d-flex align-items-center ${type === "password" && "border rounded p-3 mt-2"}`}>
				<input
					className={`${type !== "password" ? "p-3 border rounded mt-2" : "p-0 border-0"} w-100 ${inputstyle}`}
					onChange={handleChange}
					style={{ height: type === "password" ? "auto" : 56 }}
					type={(type === "password" && !showPassword) ? "password" : "text"}
					{...rest}
				/>
				{type === "password" && <EyeClosed className="ms-3 cursor-pointer" onClick={() => setShowPassword(prev => !prev)} />}
			</div>

			{isTouched && error && <div className="mt-2 align-items-center">
				<ErrorTag className="me-2" />
				<span className="text-danger small">{error}</span>
			</div>}

		</div>
	);
};

Input.propTypes = {
	type: PropTypes.string,
	inputstyle: PropTypes.string,
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	textStyle: PropTypes.string,
	label: PropTypes.string,
	isTouched: PropTypes.bool,
	error: PropTypes.string,
	handleChange: PropTypes.func,
	viewClass: PropTypes.string,
	placeholder: PropTypes.string
};
