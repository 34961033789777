import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { usePostRequest } from "../../../api/useRequestProcessor";
import { GreenTick, SDate, SEdit, SGlobe, SMap, SUser, WhiteArrow } from "../../../assets";
import { toast } from "react-toastify";

export const SummaryPreview = () => {
	const { count, applicant, name } = useSelector(({ dash }) => dash.assessments);
	const navigate = useNavigate(); //eslint-disable-line
	const dispatch = useDispatch();
	const { mutate: submitCalculate, isLoading } = usePostRequest("calculate/real-estate", "calculate"); //eslint-disable-line

	const mainApplicant = applicant[0];
	const mainApplicantsAddress = mainApplicant.address;
	const coApplicant = applicant[1];
	const coApplicantsAddress = coApplicant.address;

	const submitCalculateSummary = () => {
		submitCalculate(({ applicant: count === "1" ? [applicant[0]] : applicant, name }), {
			onSuccess: (data) => {
				toast.success(data.message);
				dispatch({ type: "RESET_ASSESSMENT" });
				navigate("/assessment/success");
			},
			onError: ({ error }) => {
				toast.error(error.message);
			}
		});
	};

	useEffect(() => {
		if (count === "1" || count === "2") {
			if (!mainApplicant.address) navigate("/assessment/assessment-details");
		} else if (count === "2") {
			if (!mainApplicant.address.street_address || !coApplicant.address.street_address) navigate("/assessment/assessment-details");
		} else navigate("/assessment");

		// console.log(mainApplicant, coApplicant);
	}, []);

	return (
		<>
			<div className="d-flex justify-content-between align-items-center bg-white customShadow rounded px-5 py-3">
				<div className="d-flex align-items-centent">
					<GreenTick className="me-2" />
					<span className="mb-0 text-prim2 longHeight">CLIENT TYPE</span>
				</div>
				<div className="d-flex align-items-centent">
					<GreenTick className="me-2" />
					<p className="mb-0 text-prim2 longHeight">ASSESSMENT DETAILS</p>
				</div>
				<div className="d-flex align-items-centent">
					<GreenTick className="me-2" />
					<p className="mb-0 text-prim2 longHeight">APPLICANT INFORMATION</p>
				</div>
				<div className="d-flex align-items-centent">
					<GreenTick className="me-2" />
					<p className="mb-0 text-prim2 longHeight">SUMMARY</p>
				</div>
			</div>

			{
				count === "1"
					? (
						<div className="d-flex bg-white customShadow rounded p-5 my-5">
							<div className="col-4">
								<div className="bg-light d-flex rounded-circle justify-content-center align-items-center" style={{ width: 80, height: 80 }}>
									<p className="mb-0 fs-4 fw-bold text-prim2">
										{mainApplicant?.first_name.charAt(0) + mainApplicant?.last_name.charAt(0)}
									</p>
								</div>

								<p className="text-muted fw-bold mt-3">Main applicant</p>
								<p className="text-muted small">Confirm the details of the applicant(s), so that we can proceed with the assessment. </p>
							</div>

							<div className="col-5 offset-2">

								<div className="d-flex mb-4">
									<div style={{ width: 40 }}>
										<SUser />
									</div>

									<div className="d-flex flex-column ms-3">
										<p className="text-muted mb-0 fs-6">Full name</p>
										<p className="mb-0 fs-6 text-prim2">{mainApplicant.first_name} {mainApplicant.last_name}</p>
									</div>

									<div style={{ width: 40 }} className="ms-auto mt-auto cursor-pointer" onClick={() => navigate("/assessment/personal-address")}>
										<SEdit />
									</div>
								</div>

								<div className="d-flex mb-4">
									<div style={{ width: 40 }}>
										<SDate />
									</div>

									<div className="d-flex flex-column ms-3">
										<p className="text-muted mb-0 fs-6">Date of birth</p>
										<p className="mb-0 fs-6 text-prim2">{new Date(mainApplicant.date_of_birth).toDateString()}</p>
									</div>

									<div style={{ width: 40 }} className="ms-auto mt-auto cursor-pointer" onClick={() => navigate("/assessment/personal-address")}>
										<SEdit />
									</div>
								</div>

								<div className="d-flex mb-4">
									<div style={{ width: 40 }}>
										<SGlobe />
									</div>

									<div className="d-flex flex-column ms-3">
										<p className="text-muted mb-0 fs-6">Nationality</p>
										<p className="mb-0 fs-6 text-prim2">{mainApplicant?.nationality.join(", ")}</p>
									</div>

									<div style={{ width: 40 }} className="ms-auto mt-auto cursor-pointer" onClick={() => navigate("/assessment/personal-address")}>
										<SEdit />
									</div>
								</div>

								<div className="d-flex mb-4">
									<div style={{ width: 40 }}>
										<SMap />
									</div>

									<div className="d-flex flex-column ms-3">
										<p className="text-muted mb-0 fs-6">Address</p>
										<p className="mb-0 fs-6 text-prim2">{mainApplicantsAddress.building_number} {mainApplicantsAddress?.street_address && `${mainApplicantsAddress.street_address},`} {mainApplicantsAddress?.city && `${mainApplicantsAddress.city},`} {mainApplicant.residency}.</p>
									</div>

									<div style={{ width: 40 }} className="ms-auto mt-auto cursor-pointer" onClick={() => navigate("/assessment/residential-address")}>
										<SEdit />
									</div>
								</div>

								{/* navigation buttons */}
								<div className="d-flex align-items-center justify-content-end mt-5">
									<Link className="text-prim2 fw-normal" to="/assessment/residential-address">
										Previous step
									</Link>

									<button onClick={submitCalculateSummary} className="btn bg-prim text-white text-center p-3 ms-4 px-4" disabled={isLoading}>
										{isLoading ? "Processing..." : <>Continue <WhiteArrow className="ms-3" /></>}
									</button>
								</div>
							</div >
						</div >
					)
					: (
						<>
							<div className="row">
								<div className="col-md-6">
									<div className="d-flex flex-column bg-white customShadow rounded p-5 my-3 my-md-5">

										<div className="bg-light d-flex mx-auto mb-3 rounded-circle justify-content-center align-items-center" style={{ width: 80, height: 80 }}>
											<p className="mb-0 fs-4 fw-bold text-prim2">
												{mainApplicant?.first_name.charAt(0) + mainApplicant?.last_name.charAt(0)}
											</p>
										</div>

										<p className="text-muted small mb-4 text-center">Confirm the details of the applicant(s), so that we can proceed with the assessment  </p>
										<p className="text-muted fw-bold mt-3">Main applicant</p>

										<div className="d-flex mb-4">
											<SUser />
											<div className="d-flex flex-column ms-3">
												<p className="text-muted mb-0 fs-6">Full name</p>
												<p className="mb-0 fs-6 text-prim2">{mainApplicant.first_name} {mainApplicant.last_name}</p>
											</div>
											<SEdit className="ms-auto mt-auto cursor-pointer" onClick={() => navigate("/assessment/personal-details")} />
										</div>

										<div className="d-flex mb-4">
											<SDate />
											<div className="d-flex flex-column ms-3">
												<p className="text-muted mb-0 fs-6">Date of birth</p>
												<p className="mb-0 fs-6 text-prim2">{new Date(mainApplicant.date_of_birth).toDateString()}</p>
											</div>
											<SEdit className="ms-auto mt-auto cursor-pointer" onClick={() => navigate("/assessment/personal-details")} />
										</div>

										<div className="d-flex mb-4">
											<SGlobe />
											<div className="d-flex flex-column ms-3">
												<p className="text-muted mb-0 fs-6">Nationality</p>
												<p className="mb-0 fs-6 text-prim2">{mainApplicant?.nationality.join(", ")}</p>
											</div>
											<SEdit className="ms-auto mt-auto cursor-pointer" onClick={() => navigate("/assessment/personal-details")} />
										</div>

										<div className="d-flex mb-4">
											<div style={{ width: 40 }}>
												<SMap />
											</div>

											<div className="d-flex flex-column ms-3">
												<p className="text-muted mb-0 fs-6">Address</p>
												<p className="mb-0 fs-6 text-prim2">{mainApplicantsAddress.building_number} {mainApplicantsAddress?.street_address && `${mainApplicantsAddress?.street_address},`} {mainApplicantsAddress.city && `${mainApplicantsAddress.city},`} {mainApplicant.residency}.</p>
											</div>

											<div style={{ width: 17 }} className="ms-auto mt-auto cursor-pointer" onClick={() => navigate("/assessment/residential-address")} >
												<SEdit />
											</div>
										</div>
									</div>
								</div>

								<div className="col-md-6">
									<div className="d-flex flex-column bg-white customShadow rounded p-5 my-3 my-md-5">

										<div className="bg-light d-flex mx-auto mb-3 rounded-circle justify-content-center align-items-center" style={{ width: 80, height: 80 }}>
											<p className="mb-0 fs-4 fw-bold text-prim2">
												{coApplicant?.first_name.charAt(0) + coApplicant?.last_name.charAt(0)}
											</p>
										</div>

										<p className="text-muted small mb-4 text-center">Confirm the details of the applicant(s), so that we can proceed with the assessment  </p>
										<p className="text-muted fw-bold mt-3">Co-applicant</p>

										<div className="d-flex mb-4">
											<SUser />
											<div className="d-flex flex-column ms-3">
												<p className="text-muted mb-0 fs-6">Full name</p>
												<p className="mb-0 fs-6 text-prim2">{coApplicant.first_name} {coApplicant.last_name}</p>
											</div>
											<SEdit className="ms-auto mt-auto cursor-pointer" onClick={() => navigate("/assessment/personal-details/co-applicant")} />
										</div>

										<div className="d-flex mb-4">
											<SDate />
											<div className="d-flex flex-column ms-3">
												<p className="text-muted mb-0 fs-6">Date of birth</p>
												<p className="mb-0 fs-6 text-prim2">{new Date(coApplicant.date_of_birth).toDateString()}</p>
											</div>
											<SEdit className="ms-auto mt-auto cursor-pointer" onClick={() => navigate("/assessment/personal-details/co-applicant")} />
										</div>

										<div className="d-flex mb-4">
											<SGlobe />
											<div className="d-flex flex-column ms-3">
												<p className="text-muted mb-0 fs-6">Nationality</p>
												<p className="mb-0 fs-6 text-prim2">{coApplicant?.nationality.join(", ")}</p>
											</div>
											<SEdit className="ms-auto mt-auto cursor-pointer" onClick={() => navigate("/assessment/personal-details/co-applicant")} />
										</div>

										<div className="d-flex mb-4">
											<div style={{ width: 40 }}>
												<SMap />
											</div>

											<div className="d-flex flex-column ms-3">
												<p className="text-muted mb-0 fs-6">Address</p>
												<p className="mb-0 fs-6 text-prim2">{coApplicantsAddress.building_number} {coApplicantsAddress?.street_address && `${coApplicantsAddress?.street_address},`} {coApplicantsAddress?.city && `${coApplicantsAddress?.city},`} {coApplicant.residency}.</p>
											</div>

											<div style={{ width: 17 }} className="ms-auto mt-auto" onClick={() => navigate("/assessment/residential-address/co-applicant")}>
												<SEdit />
											</div>
										</div>
									</div>
								</div>
							</div>

							{/* navigation buttons */}
							<div className="d-flex align-items-center justify-content-end mt-4">
								<Link className="text-prim2 fw-normal" to="/assessment/residential-address/co-applicant">
									Previous step
								</Link>

								<button onClick={submitCalculateSummary} className="btn bg-prim text-white text-center p-3 ms-4 px-4" disabled={isLoading}>
									{isLoading ? "Processing..." : <>Continue <WhiteArrow className="ms-3" /></>}
								</button>
							</div>
						</>
					)
			}

		</>
	);
};
