import axios from "axios";
import "./App.css";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { DefaultRoutes, MainRoutes } from "./routes";

function App() {
	const { isLoggedIn, testToken, token } = useSelector((state) => state.auth);

	useEffect(() => {
		// console.log(testToken, "-----------test");
		// console.log(token, "-----------token");
		// console.log(isLoggedIn, "-----------login");

		axios.defaults.headers.common.Authorization = `Bearer ${isLoggedIn ? token : testToken}`;
	}, [token, testToken, isLoggedIn]);

	useEffect(() => {
		// getCountries();
	}, []);

	switch (isLoggedIn) {
		case true:
			return <MainRoutes />;
		default:
			return <DefaultRoutes />;
	}
}

export default App;
