import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { usePlacesWidget } from "react-google-autocomplete";
import { LocationIcon, WhiteArrow } from "../../../../assets";
import { Input } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { LOGIN, UPDATE_COMPANY } from "../../../../store";
import { usePostRequest } from "../../../../api/useRequestProcessor";
import { toast } from "react-toastify";

export const NONUKBusinessAddress = () => {
	const dispatch = useDispatch();
	const { navigate } = useNavigate();
	const { regPayload } = useSelector(state => state.auth);
	const { mutate: updateCompany, isLoading } = usePostRequest("auth/submit-company");
	const YOUR_GOOGLE_MAPS_API_KEY = "AIzaSyAinM73NYLIGGokmdW7YIhBXW_vzIhJJi4";

	const { ref } = usePlacesWidget({
		apiKey: YOUR_GOOGLE_MAPS_API_KEY,
		onPlaceSelected: (place) => {
			setFieldValue("street_address1", place?.formatted_address);
			dispatch({ type: UPDATE_COMPANY, payload: { street_address1: place?.formatted_address } });
		}
	});

	const onSubmit = (values) => {
		dispatch({ type: UPDATE_COMPANY, payload: { ...values } });

		delete values.countryCode;
		delete values.location;

		updateCompany(values, {
			onSuccess: (response) => {
				toast.success("Company profile successfully submitted!");
				dispatch({ type: LOGIN, payload: response?.access_token });
				navigate("/proceed_to_login");
			},
			onError: ({ error }) => {
				// console.log({ error });
				toast.error(error?.message);
			}
		});
	};

	const [showManual, setShowManual] = useState(false);
	const { values, handleChange, errors, touched, setValues, handleSubmit, setFieldValue } = useFormik({
		initialValues: { street_address1: "", street_number: "", street_address2: "", post_code: "", state: "", location: "", country: "" },
		onSubmit
	});

	useEffect(() => {
		setValues({ ...values, ...regPayload });
	}, [regPayload]);

	return (
		<div className="mainBody comp overflow-auto">
			<h2 className="fw-bold text-prim2">Find address</h2>
			<p className="fw-light text-muted mb-5">
				You are almost there, just a few more information.
			</p>

			<form onSubmit={handleSubmit} className="mb-5">
				{
					(showManual)
						? <>

							<Input label={"Building Number/Name"}
								name="building_number"
								value={values.building_number}
								onChange={handleChange}
								isTouched={touched.building_number}
								error={errors.building_number}
								placeholder=""
							/>

							<Input viewClass={"mt-4"}
								label={"Address Line 1"}
								name="street_address1"
								value={values.street_address1}
								required
								onChange={handleChange}
								isTouched={touched.street_address1}
								error={errors.street_address1}
								placeholder=""
							/>

							<Input viewClass={"mt-4"}
								label={"Address Line2"}
								name="street_address2"
								value={values.street_address2}
								// required
								onChange={handleChange}
								isTouched={touched.street_address2}
								error={errors.street_address2}
								placeholder=""
							/>

							<Input viewClass={"mt-4"}
								label={"City"}
								name="city"
								value={values.city}
								required
								onChange={handleChange}
								isTouched={touched.city}
								error={errors.city}
								placeholder=""
							/>

							<div className="d-flex my-4">
								<Input
									viewClass={"w-50 pe-3"}
									label={"State / Province (optional)"}
									name="state"
									value={values.state}
									onChange={handleChange}
									isTouched={touched.state}
									error={errors.state}
									placeholder="State"
								/>

								<Input
									viewClass={"w-50 ps-3"}
									label={"ZIP / Post code"}
									name="post_code"
									value={values.post_code}
									required
									onChange={handleChange}
									isTouched={touched.post_code}
									error={errors.post_code}
									placeholder="439 045"
								/>
							</div>

							<Input
								label={"Country"}
								name="country"
								value={values.country}
								disabled
								required
								onChange={handleChange}
								isTouched={touched.country}
								error={errors.country}
								placeholder="439045"
							/>

							<div className="d-flex align-items-center mt-5">
								<Link className="text-prim2 fw-normal" to="/business-account/non_uk">
									Previous step
								</Link>

								<button type="submit" className="btn bg-prim text-white text-center p-3 ms-4 px-4" disabled={isLoading}>
									{isLoading ? "please wait..." : <>Submit <WhiteArrow className="ms-3" /></>}
								</button>
							</div>
							{/* </div> */}
						</>
						: <>
							<div className="d-flex mt-2 p-3 border rounded align-items-center">
								<LocationIcon className="me-3" />
								<input
									ref={ref}
									className="border-0 w-100 noFocusWithin"
									type={"text"}
									value={values.street_address1}
									onSubmit={() => { }}
									onChange={handleChange}
									name="street_address1"
									placeholder="Enter the first line of the address..."
								/>
							</div>

							<div className="d-flex mt-5 flex-column">
								<p className="text-prim2 cursor-pointer mb-0" onClick={() => setShowManual(prev => !prev)}>Enter address manually</p>

								<div className="mt-4 d-flex align-items-center">
									<Link className="" to={"/business-account/non_uk"}>
										Previous step
									</Link>

									<button className="btn bg-prim text-white text-center p-3 ms-4 px-4" onClick={() => setShowManual(prev => !prev)}>
										Next <WhiteArrow className="ms-3" />
									</button>
								</div>
							</div>
						</>
				}

			</form>
		</div >
	);
};
