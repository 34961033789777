import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Tag } from "../../../assets/svg";

export const Plan = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		dispatch({ type: "SET_PAGE_TITLE", payload: "SELECT PLAN" });
	}, []);

	return (
		<div className="row">
			<div className="col-12">
				<h2 className="text-prim2 fw-bold">Select a plan</h2>
				<p className="text-muted fw-bold">Read all plans or contact us we will guide you better</p>

				<div className="mt-5 me-5">
					<div className="me-5 d-flex flex-column flex-md-row planCards">
						<div className="col-12 col-md-4 me-md-3 mb-3 mb-md-0 me-0 border">
							<div className="p-5 d-flex flex-column">
								<h1 className="text-prim2 fw-bold mb-5 fs-1">£5</h1>
								<h4 className="text-prim2 fw-bold">Starter</h4>
								<p className="text-muted">Pay as you go</p>

								<ul className="border-top border-prim2 mt-4 pt-5 mb-5 checkedIconClass">
									<li className="text-prim2">1 risk assessment.</li>
									<li className="text-prim2 mt-1">Report avalaible for 30 days.</li>
									<li className="text-prim2 mt-1">Comprehensive report.</li>
									<li className="text-prim2 mt-1">Download PDF report.</li>
								</ul>

								<button onClick={() => navigate("/checkout", { state: "starter" })} className="mt-5 bg-prim2 text-white fw-bold btn w-100 py-3">Choose plan</button>
							</div>
						</div>

						<div className="col-12 col-md-4 me-md-3 mb-3 mb-md-0 me-0 border position-relative">
							<Tag className="position-absolute" style={{ left: -4 }} />
							<div className="p-5 d-flex flex-column">
								<h1 className="text-prim2 fw-bold mb-5 fs-1">£80</h1>
								<h4 className="text-prim2 fw-bold">Standard</h4>
								<p className="text-muted">Our most popular plan</p>

								<ul className="border-top border-prim2 mt-4 pt-5 mb-5 checkedIconClass">
									<li className="text-prim2">20 risk assessments.</li>
									<li className="text-prim2 mt-1">Report avalaible for 30 days.</li>
									<li className="text-prim2 mt-1">Comprehensive report.</li>
									<li className="text-prim2 mt-1">Download PDF report.</li>
								</ul>

								<button onClick={() => navigate("/checkout", { state: "standard" })} className="mt-5 bg-prim2 text-white fw-bold btn w-100 py-3">Choose plan</button>
							</div>
						</div>

						<div className="col-12 col-md-4 me-md-3 mb-3 mb-md-0 me-0 border">
							<div className="p-5 d-flex flex-column">
								<h1 className="text-prim2 fw-bold mb-5 fs-1"> </h1>
								<h4 className="text-prim2 fw-bold mt-5">Custom</h4>
								<p className="text-muted">Customized your plan</p>

								<ul className="border-top border-prim2 mt-4 pt-5 mb-5 checkedIconClass">
									<li className="text-prim2">Custom number of assessment</li>
									<li className="text-prim2 mt-1">Report avalaible for 30 days.</li>
									<li className="text-prim2 mt-1">Comprehensive report.</li>
									<li className="text-prim2 mt-1">Download PDF report.</li>
								</ul>

								<a href={"mailto:info@spectrasolutions.inc"} className="mt-5 bg-prim2 text-white fw-bold btn w-100 py-3">Contact support</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
