import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { MainLayout, AccountLayout } from "../components";
import {
	AssessmentDetails, AssessmentHome, Business, Dashboard, FindAssessmentAddress, FindAssessmentAddressCoApplicant, HelpHome,
	PersonalAssessmentDetails, PersonalAssessmentDetailsCoApplicant, Profile, ResidentialAssessmentDetails, ResidentialAssessmentDetailsCoApplicant, Result, Security, SetAccessment,
	SubmitSuccess, SummaryPreview, SupportHome, Plan, Checkout
} from "../pages";

export function MainRoutes() {
	return (
		<BrowserRouter>
			<Routes>

				<Route path="/checkout" element={<Checkout />} />
				{/* all routes */}
				<Route path={"/"} element={<MainLayout />}>
					<Route index element={<Dashboard />} />
					<Route path="/help" element={<HelpHome />} />
					<Route path="/support" element={<SupportHome />} />
					<Route path="/assessment/result/:id" element={<Result />} />
					<Route path="/assessment/plan/:id" element={<Plan />} />

					<Route path="/account" element={<AccountLayout />}>
						<Route index element={<Profile />} />
						<Route path="/account/business" element={<Business />} />
						<Route path="/account/security" element={<Security />} />

						{/* redirect to default if not found */}
						<Route path="*" element={<Navigate to={"/account"} />} />
					</Route>

					{/* redirect to default if not found */}
					<Route path="*" element={<Navigate to={"/"} />} />
				</Route>

				{/* Assessment routes */}
				<Route path="/assessment" element={<MainLayout />}>
					<Route index element={<AssessmentHome />} />
					<Route path="/assessment/client-type" element={<SetAccessment />} />
					<Route path="/assessment/assessment-details" element={<AssessmentDetails />} />
					<Route path="/assessment/personal-details" element={<PersonalAssessmentDetails />} />
					<Route path="/assessment/find-address" element={<FindAssessmentAddress />} />
					<Route path="/assessment/residential-address" element={<ResidentialAssessmentDetails />} />
					<Route path="/assessment/personal-details/co-applicant" element={<PersonalAssessmentDetailsCoApplicant />} />
					<Route path="/assessment/find-address/co-applicant" element={<FindAssessmentAddressCoApplicant />} />
					<Route path="/assessment/residential-address/co-applicant" element={<ResidentialAssessmentDetailsCoApplicant />} />
					<Route path="/assessment/summary" element={<SummaryPreview />} />
					<Route path="/assessment/success" element={<SubmitSuccess />} />

					{/* redirect to default if not found */}
					<Route path="*" element={<Navigate to={"/assessment"} />} />
				</Route>

			</Routes>
		</BrowserRouter>
	);
}
