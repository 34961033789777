import * as yup from "yup";

export const RegisterSchema = yup.object({
	first_name: yup
		.string()
		.required("First name is required")
		.test("isValid", "Enter a valid name ", (value) => {
			if (value?.length < 3) return false;
			else return true;
		}),
	last_name: yup
		.string()
		.required("Last name is required")
		.test("isValid", "Enter a valid name ", (value) => {
			if (value?.length < 3) return false;
			else return true;
		}),
	email: yup.string().email("Enter a valid email").required("Email is required"),
	terms: yup.boolean().required("Email is required")
		.test("isValid", "You must accept the terms and conditions", (value) => {
			if (value) return true;
			else return false;
		}),
	password: yup
		.string()
		.required("Password is required")
		.test("isValid", "Minimum of 8 characters", (value) => {
			if (value?.length < 8) return false;
			else return true;
		})
});
