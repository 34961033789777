import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { GreenTick, WhiteArrow } from "../../../assets";
import { CountryDropdown, Input } from "../../../components";
import { AssessmentApplicantAddressSchema } from "../../../components/forms/accessmentSchema";
import { SET_PAGE_TITLE, CHANGE_ASSESSMENT_APPLICANT_1 } from "../../../store/constants";

/* eslint-disable camelcase */
export const ResidentialAssessmentDetails = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { dash } = useSelector(state => state);

	const { values, handleChange, handleSubmit, errors, touched, setValues } = useFormik({
		initialValues: {
			building_number: "",
			street_address: "",
			city: "",
			state: "",
			post_code: "",
			country: ""
		},
		validationSchema: dash.assessments.applicant[0].residency.toLowerCase() === "united kingdom" ? AssessmentApplicantAddressSchema : null,
		onSubmit: (values) => {
			dispatch({ type: CHANGE_ASSESSMENT_APPLICANT_1, payload: { address: values } });

			if (dash.assessments.count === "1") navigate("/assessment/summary");
			else navigate("/assessment/personal-details/co-applicant");
		}
	});

	useEffect(() => {
		dispatch({ type: SET_PAGE_TITLE, payload: "CREATE ASSESSMENT" });
	}, []);

	useEffect(() => {
		const { address, residency } = dash.assessments.applicant[0];
		setValues({ ...address, country: residency });
	}, [dash]);

	return (
		<>
			<div className="d-flex justify-content-between align-items-center bg-white customShadow rounded px-5 py-3">
				<div className="d-flex align-items-centent">
					<GreenTick className="me-3" />
					<span className="mb-0 text-prim2 longHeight">CLIENT TYPE</span>
				</div>

				<div className="d-flex align-items-centent">
					<GreenTick className="me-3" />
					<p className="mb-0 text-prim2 longHeight">ASSESSMENT DETAILS</p>
				</div>

				<div className="d-flex align-items-centent">
					<p className="small border border-prim2 rounded-circle optionText mb-0 text-prim2 d-flex align-items-center justify-content-center me-3"> 3 </p>
					<p className="mb-0 text-prim2 longHeight">APPLICANT INFORMATION</p>
				</div>

				<div className="d-flex align-items-centent">
					<p className="small border border-muted rounded-circle optionText mb-0 text-muted d-flex align-items-center justify-content-center me-3"> 4 </p>
					<p className="mb-0 text-muted longHeight">SUMMARY</p>
				</div>
			</div>

			<div className="d-flex bg-white customShadow rounded p-5 my-5">
				<div className="col-4">
					<p className="text-muted fw-bold">Main applicant</p>
					<p className="text-prim2 fw-bold fs-4">Residential address {dash.assessments.applicant[0].residency.toLowerCase().includes("united kingdom") ? "" : "(Optional)"}</p>
					<p className="text-muted small">You are almost there, just a few more information. </p>
				</div>

				<form className="col-5 offset-2" onSubmit={handleSubmit}>
					<Input
						placeholder={"building number/ name etc."}
						label={"Address"}
						viewClass={"mt-4"}
						name={"building_number"}
						value={values.building_number}
						onChange={handleChange}
						error={errors.building_number}
						isTouched={touched.building_number}
					/>
					<Input
						placeholder={"Maiden, middleton etc."}
						viewClass={"mt-1"}
						name={"street_address"}
						value={values.street_address}
						onChange={handleChange}
						error={errors.street_address}
						isTouched={touched.street_address}
					/>

					<Input
						placeholder={"City name eg: London etc."}
						label={"City"}
						viewClass={"mt-4"}
						name={"city"}
						value={values.city}
						onChange={handleChange}
						error={errors.city}
						isTouched={touched.city}
					/>

					<div className="d-flex justify-content-between mt-4">
						<Input
							placeholder={"residency"}
							viewClass={"w-50 me-3"}
							label={"State/Province "}
							name={"state"}
							value={values.state}
							onChange={handleChange}
							error={errors.state}
							isTouched={touched.state}
						/>
						<Input
							placeholder={"Zip code"}
							viewClass={"w-50 ms-3"}
							label={"Zip/Postal code"}
							name={"post_code"}
							value={values.post_code}
							onChange={handleChange}
							error={errors.post_code}
							isTouched={touched.post_code}
						/>
					</div>

					<CountryDropdown
						disabled
						label={"Country"}
						value={values?.country}
					/>

					{/* navigation buttons */}
					<div className="d-flex align-items-center justify-content-end mt-5">
						<Link className="text-prim2 fw-normal" to={dash.assessments.applicant[0].residency.toLowerCase().includes("united kingdom") ? "/assessment/find-address" : "/assessment/personal-details"}>
							Previous step
						</Link>

						<button type="submit" className="btn bg-prim text-white text-center p-3 ms-4 px-4">
							{<>Continue <WhiteArrow className="ms-3" /></>}
						</button>
					</div>
				</form>
			</div>
		</>
	);
};
