import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { BriefCase, Lock, User } from "../../assets";

export const AccountLayout = () => {
	const { pathname } = useLocation();
	const path = pathname.split("/");

	return (
		<div className="row">
			<div className="col-4">
				<div className="d-flex flex-column">
					<Link to={"/account"} className={`px-5 py-2 mb-2 d-flex align-items-center ${!path[2] ? "routeBorder" : ""}`}>
						<User className="me-3" width={25} /> My Profile
					</Link>
					<Link to={"/account/business"} className={`px-5 py-2 mb-2 d-flex align-items-center ${path[2] === "business" ? "routeBorder" : ""}`}>
						<BriefCase className="me-3" width={25} />Business Information
					</Link>
					<Link to={"/account/security"} className={`px-5 py-2 mb-2 d-flex align-items-center ${path[2] === "security" ? "routeBorder" : ""}`}>
						<Lock className="me-3" width={25} />Password & Security
					</Link>
				</div>
			</div>

			<div className="col-6 offset-1 pb-5">
				<Outlet />
			</div>
		</div>
	);
};
